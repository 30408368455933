/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { buildTree } from '../../module/contentTree';
import { getStaticHtml } from './staticContentHelpers';
import { getStructuredDocumentRoot, getCurrentPageWebConfiguration } from './themaHelpers';
import { HOMEPAGE_COVER_IMAGE } from '../static/constants';


export const getContentPageItems = (source, state) => (state.content.pages[source]
  ? state.content.pages[source] : undefined);

// get static content for a menu (could be header or footer)
export const getMenuContent = (state, key) => {
  const items = getContentPageItems(`/content/${key}`, state);
  const tree = items && items.length ? buildTree(getStructuredDocumentRoot(items).key, items, state.configurations.webpages) : undefined;
  if (!tree) {
    return {
      loading: true,
    };
  }

  const menuHtml = getStaticHtml(tree);

  return {
    html: menuHtml,
    loading: menuHtml === undefined,
  };
};

export const getMetatags = (state, tree = {}) => {
  const webconfig = getCurrentPageWebConfiguration(state);

  let metatags;

  if (!webconfig) {
    return null;
  }

  switch (webconfig.type) {
    case 'WWW_HOME_PAGE':
      metatags = {
        url: 'https://www.katholiekonderwijs.vlaanderen/',
        title: 'Katholiek Onderwijs Vlaanderen',
        description: 'Als netwerkorganisatie bouwen aan kwaliteitsvol en inspirerend onderwijs',
        image: HOMEPAGE_COVER_IMAGE,
      };
      break;
    case 'WWW_CURRENT_THEME_PAGE':
      metatags = {
        url: webconfig ? webconfig.path : tree.$$expanded.permalink,
        title: tree.title,
        image: tree.sharedCoverImage,
      };
      break;
    case 'WWW_NEWS_DETAIL':
      metatags = {
        url: webconfig ? webconfig.path : tree.$$expanded.permalink,
        title: tree.title,
        description: tree.description,
        image: tree.sharedThumbImage,
      };
      break;
    default:
      metatags = {
        url: webconfig ? webconfig.path : tree.$$expanded.permalink,
        title: tree.title,
        description: tree.description,
        image: tree.sharedCoverImage,
      };
      break;
  }

  return {
    ...metatags,
    robots: 'index,follow',
    googlebot: 'index,follow',
  };
};

import * as ACTIONS from '../../reduxLoop/actions/newsActions';
import settings from '../../config/settings';

require('./wsNewsOverview.scss');

class wsNewsOverview {
  constructor($scope, $element, ngMeta, $sce, $ngRedux, Analytics) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;

    this.proUrl = settings.apisAndUrls.pro;

    this.itemsPerPage = 3;
    this.page = 1;
    this.openGraphTagsUpdated = false;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.newsOverview.metatags) {
        this.$scope.$emit('dynamicMetaDataUpdated', state.vm.newsOverview.metatags);
        this.openGraphTagsUpdated = true;
        if (state.vm.newsOverview.firstLoadOfPage) {
          const { title: ngMetaTitle } = state.vm.newsOverview.metatags;
          this.analytics.trackEvent('view_page', { page_category_1: 'home', page_category_2: 'nieuws', page_title: ngMetaTitle?.toLowerCase() || undefined });
        }
      }

      return {
        allitems: state.vm.newsOverview.allitems,
        itemsToDisplay: state.vm.newsOverview.itemsToDisplay,
        filter: state.vm.newsOverview.filter,
        loading: state.vm.newsOverview.loading,
      };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadNews(this.filter));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  onFilter(s, filter) {
    this.$ngRedux.dispatch(ACTIONS.updateNewsFilter(filter));
  }

  onClearFilter() {
    this.$ngRedux.dispatch(ACTIONS.clearNewsFilter());
  }

  onLoadMore() {
    this.$ngRedux.dispatch(ACTIONS.loadMoreNews());
  }
}

export default {
  template: require('./wsNewsOverview.html'),
  controllerAs: 'ctrl',
  controller: wsNewsOverview,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};

require('./wsNoResults.scss');

class wsNoResults {
    constructor() {
        'ngInject';
    }
}

module.exports = {
    template: require('./wsNoResults.html'),
    controllerAs: 'ctrl',
    controller: wsNoResults,
};

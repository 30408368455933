import { WWW_COOKIES_PAGE } from '../static/constants';
import { getCurrentPageWebConfigurationType } from '../helpers/themaHelpers';

export default (tree, state) => {
  if (!tree || getCurrentPageWebConfigurationType(state) !== WWW_COOKIES_PAGE) {
    return {
      loading: true,
    };
  }

  return {
    title: tree.title,
    description: tree.description,
    coverImage: tree.coverImage.href,
    firstLevelItems: tree ? tree.children : [],
    loading: !tree,
  };
};

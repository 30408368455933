class wsContentBlockReferenceGroup {
  constructor(settings, $scope, dataService, routerService) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.dataService = dataService;
    this.routerService = routerService;
  }

  $onInit() {
    this.referenceTypes = {};

    if (this.sItem.children) {
      this.sItem.children.forEach(item => this.getReferenceType(item));
    }
  }

  async getReferenceType(item) {
    const { routerService } = this;
    async function findPageItemFromSub(branchMap, itemHref) {
      const current = branchMap[itemHref];

      if (current.$$relationsFrom && current.$$relationsFrom.length > 0) {
        const websiteItem = await routerService.getWebpageInfoFromHref(
          current.$$relationsFrom[0].$$expanded.to.href,
        );

        if (websiteItem && ['MINI_DATABASE', 'BLOG', 'THEME_PAGE', 'THEME_HOME_PICTURE', 'THEME_HOMEPAGE', 'THEME_HOME_FULL'].includes(websiteItem.type)) {
          return websiteItem.type;
        }

        return findPageItemFromSub(branchMap, current.$$relationsFrom[0].$$expanded.to.href);
      }
      return null;
    }
    const ancestors = await this.dataService.getAncestors(item.references[0]);

    const ancestorMap = {};
    ancestors.forEach((content) => { ancestorMap[content.$$meta.permalink] = content; });
    this.referenceTypes[item.href] = await findPageItemFromSub(ancestorMap, item.references[0]);
  }
}

module.exports = {
  template: require('./wsContentBlockReferenceGroup.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockReferenceGroup,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

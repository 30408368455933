/* eslint-disable func-names */

const service = (settings, $location, md5, $anchorScroll) => {
  'ngInject';

  const that = {};

  that.getValidUrl = function (href) {
    const newUrl = that.cachedNewUrls.get(href);

    if (newUrl) {
      return newUrl;
    }
    const webpageInfo = that.getWebpageInfoFromPath(href);

    if (webpageInfo) {
      return webpageInfo.path;
    }

    return href;
  };

  that.getWebpageInfoFromPath = function (path) {
    try {
      const cachedVersion = that.cachedPaths.get(path);
      if (cachedVersion) {
        return cachedVersion;
      }
      // console.warn('No webpage item found for ' + path);
      return null;
    } catch (e) {
      throw e;
    }
  };

  that.getWebpageInfoFromHref = function (href) {
    try {
      const cachedVersion = that.cachedSources.get(href);
      if (cachedVersion) {
        return cachedVersion;
      }
      // console.warn('No webpage item found for ' + href);
      return null;
    } catch (e) {
      throw e;
    }
  };

  that.cachedPaths = new Map();
  that.cachedSources = new Map();
  that.cachedNewUrls = new Map();

  /** TODO: Rewrite this function if you actually find it useful.
   * initWebpages is not defined, so the switch func will always fail.
   * Initialization of initWebpages was removed by mistake.
   * Is this method still needed? Can WWW documents include reference groups?
   * That is the only place where the function is used.
   * These questions should be answered in the next www refactor. */
  that.switch = async function (href, notRedirectNotFound) {
    that.initWebpages.then(() => {
      try {
        const newUrl = that.cachedNewUrls.get(href);
        const cachedVersion = that.cachedSources.get(href);
        const params = $location.$$search;

        if (newUrl) {
          // window.location.href = newUrl;
          $location.path(newUrl).search(params).replace();
        } else if (cachedVersion) {
          $location.path(cachedVersion.path).search(params).replace();
        } else {
          console.warn(`No content item found for ${href}`);
          if (!notRedirectNotFound) {
            $location.path(href);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  that.generateHash = function (key) {
    return `_${md5.createHash(key).slice(0, 8)}`;
  };

  that.scrollTo = function (anchor) {
    $anchorScroll(anchor);
    $location.search('scrollTo', null).replace();
  };

  that.isPreview = function () {
    if (!window.opener) return false;
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('preview') && urlParams.has('redactie');
  };

  return that;
};

angular.module('services').factory('routerService', service);

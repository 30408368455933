import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import { FOOTER_MENU_KEY } from '../../reduxLoop/static/constants';
import contentUtils from '../../utils/contentUtils';

require('./wsFooter.scss');

class wsFooter {
  constructor($ngRedux, $sce) {
    'ngInject';

    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
    this.source = `/content/${FOOTER_MENU_KEY}`;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      body: this.$sce.trustAsHtml(contentUtils.decodeHTML(state.vm.footer.html)),
      loading: state.vm.footer.loading,
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.source));
  }
}

export default {
  template: '<div ng-bind-html="ctrl.body"></div>',
  controllerAs: 'ctrl',
  controller: wsFooter,
};

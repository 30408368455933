import app from '../app/app';

app.component('appRoot', require('./appRoot').default);

app.component('wsHeader', require('./header/wsHeader').default);
app.component('wsHeaderCompact', require('./header/wsHeaderCompact').default);
app.component('wsMobileMenu', require('./header/wsMobileMenu').default);
app.component('wsOverlayMenu', require('./header/wsOverlayMenu').default);
app.component('wsHeaderLoading', require('./header/wsHeaderLoading').default);
app.component('wsFooter', require('./wsFooter').default);

app.component('wsCookieConsent', require('./wsCookieConsent').default);

app.component('wsLoader', require('./wsLoader'));
app.component('ws404', require('./errorPages/ws404'));
app.component('wsNoAccess', require('./errorPages/wsNoAccess'));
app.component('ws500', require('./errorPages/ws500'));
app.component('wsBackToTop', require('./wsBackToTop'));

app.component('wsLoadMore', require('./wsLoadMore'));
app.component('wsNoResults', require('./wsNoResults'));

// TODO remove
app.component('wsHardcodedTest', require('./hardcodedTest'));

/* News Items */
app.component('wsNewsItem', require('./news/wsNewsItem').default);
app.component('wsNewsFilter', require('./news/wsNewsFilter'));

/* Content */
require('./content');

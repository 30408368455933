/* eslint-disable max-len */
/* eslint-disable func-names */

import { HOMEPAGE_COVER_IMAGE } from '../reduxLoop/static/constants';
import contentUtils from '../utils/contentUtils';

export default ($stateProvider, $urlRouterProvider, $locationProvider, ngMetaProvider) => {
  $locationProvider.html5Mode(true);

  $urlRouterProvider.otherwise(($injector, $location) => {
    $injector.invoke(['$state', $state => $state.transitionTo('transitioning', {
      pathToResolve: $location.path(),
      search: $location.search(),
    },
    { location: false })]);
  });

  $stateProvider
    .state('home', {
      cache: false,
      component: 'wsHome',
      meta: {
        disableUpdate: true,
      },
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })

    .state('static', {
      cache: false,
      component: 'wsStaticPage',
      meta: {
        disableUpdate: true,
      },
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })
    .state('transitioning', {
      cache: false,
      component: 'wsLoader',
      meta: {
        disableUpdate: true,
      },
      params: {
        pathToResolve: null,
        search: null,
      },
      resolve: {
        sWidth: () => 100,
        sHeight: () => 100,
        sColor: () => '#B72A8D',
        sShowHeader: ($stateParams) => {
          'ngInject';

          return contentUtils.isHomePath($stateParams.pathToResolve);
        },
        sShowCompactHeader: ($stateParams) => {
          'ngInject';

          return !contentUtils.isHomePath($stateParams.pathToResolve);
        },
      },
    })

    /* Themes */
    .state('themeDetail', {
      cache: false,
      component: 'wsThemeDetail',
      meta: {
        disableUpdate: true,
      },
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })

    /* Tekst pages */
    .state('information', {
      cache: false,
      component: 'wsInformation',
      meta: {
        disableUpdate: true,
      },
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })

    /* Cookies page */
    .state('cookies', {
      cache: false,
      component: 'wsCookies',
      meta: {
        disableUpdate: true,
      },
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })

    /* View Options */
    .state('viewOptions', {
      url: '/view-options',
      component: 'wsInitialViewOptions',
      params: {
        lastState: null,
      },
      resolve: {
      },
    })

    /* News */
    .state('newsDatabank', {
      url: '/nieuwsoverzicht?q,types,van,tot',
      component: 'wsNewsOverview',
      meta: {
        disableUpdate: true,
      },
      resolve: {
        sNews: () => {
          'ngInject';
        },
      },
    })
    .state('newsDetail', {
      component: 'wsNewsDetail',
      params: {
        source: null,
      },
      resolve: {
        sSource: ($stateParams) => {
          'ngInject';

          return $stateParams.source;
        },
      },
    })

    /* Search */
    .state('search', {
      url: '/zoeken?q',
      component: 'wsSearch',
    })

    .state('404', {
      component: 'ws404',
      meta: {
        robots: 'noindex,nofollow',
        googlebot: 'noindex,nofollow',
      },
    })
    .state('500', {
      component: 'ws500',
      meta: {
        disableUpdate: true,
      },
    })
    .state('noAccess', {
      component: 'wsNoAccess',
      meta: {
        disableUpdate: true,
      },
    })
    // TODO remove this when no needed anymore
    .state('testhardcoded', {
      cache: false,
      url: '/testhardcoded',
      component: 'wsHardcodedTest',
      meta: {
        disableUpdate: true,
      },
    });

  ngMetaProvider.setDefaultTitle('Katholiek Onderwijs Vlaanderen');
  ngMetaProvider.setDefaultTag('robots', 'index,follow');
  ngMetaProvider.setDefaultTag('googlebot', 'index,follow');
  ngMetaProvider.setDefaultTag('image', HOMEPAGE_COVER_IMAGE);
};

import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import contentUtils from '../../utils/contentUtils';

class wsStaticPage {
  constructor($scope, $element, ngMeta, $sce, $ngRedux, Analytics) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
    this.openGraphTagsUpdated = false;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.staticPage.metatags) {
        this.$scope.$emit('dynamicMetaDataUpdated', state.vm.staticPage.metatags);
        this.openGraphTagsUpdated = true;

        const { title: ngMetaTitle } = state.vm.staticPage.metatags;
        const analyticsParams = { page_title: ngMetaTitle?.toLowerCase() || undefined };

        const bodyContent = new DOMParser().parseFromString(state.vm.staticPage.html, 'text/html');
        const breadCrumbs = bodyContent.getElementsByClassName('kov-breadcrumb__item');
        if (breadCrumbs.length > 0) {
          analyticsParams.page_category_1 = 'home';
          for (let n = 0; n < breadCrumbs.length; n += 1) {
            analyticsParams[`page_category_${n + 2}`] = breadCrumbs[n].textContent?.trim().toLowerCase();
          }
        }
        this.analytics.trackEvent('view_page', analyticsParams);
      }

      return {
        body: this.$sce.trustAsHtml(contentUtils.decodeHTML(state.vm.staticPage.html)),
        coverImage: state.vm.staticPage.coverImage,
        loading: state.vm.staticPage.loading,
      };
    })(this);

    // this.$ngRedux.dispatch(ACTIONS.initStaticPage(this.sSource));
    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }
}

export default {
  template: require('./wsStaticPage.html'),
  controllerAs: 'ctrl',
  controller: wsStaticPage,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};

const { showCookiesModal } = require('../../../reduxLoop/actions/cookiesActions');

require('./wsContentBlockVideo.scss');

class wsContentBlockVideo {
  constructor($scope, $uibModal, $cookies, $ngRedux, $sce) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.$cookies = $cookies;
    this.$sce = $sce;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      showVideo: state.vm.cookies.socialCookiesAccepted,
    }))(this);

    this.videoUrl = this.$sce.trustAsResourceUrl(this.sItem.videoPlayback);
  }

  openCookieModal() {
    this.$ngRedux.dispatch(showCookiesModal(this.$uibModal, this.$cookies));
  }
}

module.exports = {
  template: require('./wsContentBlockVideo.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockVideo,
  bindings: {
    sItem: '<',
    sLevel: '<',
    sColor: '<',
  },
};

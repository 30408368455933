class wsHeaderCompact {
  constructor($scope, $location, $ngRedux, settings) {
    'ngInject';

    this.$scope = $scope;
    this.$location = $location;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      title: state.vm.header.currentPageTitle,
      language: state.vm.header.language,
    }))(this);
  }

  menuClass() {
    return this.sInitHidden ? 'home-fixed' : '';
  }
}

export default {
  template: require('./wsHeaderCompact.html'),
  controllerAs: 'ctrl',
  controller: wsHeaderCompact,
  bindings: {
    sTitle: '<',
    sTitlePath: '<',
    sInitHidden: '<',
  },
};

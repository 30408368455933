/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/cookiesCommands';
import * as ANALYTICS_COMMANDS from '../commands/analyticsCommands';
import * as ACTIONS from '../actions/cookiesActions';
import {
  cookiesAccepted,
  isSocialCookiesAccepted,
  isAnalyticCookiesAccepted,
  isACrawler,
} from '../helpers/cookiesHelpers';
import { COOKIE_PAGE_PATH } from '../static/constants';

const initialState = {
  isACrawler: isACrawler(),
  isModalOpen: false,
  cookiesModal: null,
  socialCookiesAccepted: false,
  analyticCookiesAccepted: false,
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.INIT_SITE_COOKIES: {
      // only show cookies modal if the user is not a crawler bot, no accepted cookies yet and is not /cookies path
      const showCookiesModal = !state.isACrawler && !cookiesAccepted(action.payload.$cookies) && action.payload.$location.$$path !== COOKIE_PAGE_PATH;

      const socialCookiesAccepted = isSocialCookiesAccepted(action.payload.$cookies);
      const analyticCookiesAccepted = isAnalyticCookiesAccepted(action.payload.$cookies);

      return loop({
        ...state,
        socialCookiesAccepted,
        analyticCookiesAccepted,
        $location: action.payload.$location,
        analytics: action.payload.analytics,
      },
      Cmd.list([
        Cmd.run(ANALYTICS_COMMANDS.default, {
          args: [action.payload.analytics, analyticCookiesAccepted],
        }),
        showCookiesModal ? Cmd.run(COMMANDS.showCookiesModalCmd, {
          args: [action.payload.$uibModal, action.payload.$cookies],
          successActionCreator: ACTIONS.setCookiesModal,
        }) : Cmd.none,
      ]));
    }

    case ACTION_TYPES.SHOW_COOKIES_MODAL: {
      return loop({
        ...state,
      },
      Cmd.run(COMMANDS.showCookiesModalCmd, {
        args: [action.payload.$uibModal, action.payload.$cookies],
        successActionCreator: ACTIONS.setCookiesModal,
      }));
    }

    case ACTION_TYPES.SET_COOKIES_MODAL: {
      return {
        ...state,
        cookiesModal: action.payload,
      };
    }

    case ACTION_TYPES.CLOSE_COOKIES_MODAL: {
      return loop({
        ...state,
        cookiesModal: null,
      },
      Cmd.run(COMMANDS.closeCookiesModalCmd, {
        args: [state.cookiesModal],
      }));
    }

    case ACTION_TYPES.SET_COOKIES: {
      return loop({
        ...state,
      },
      Cmd.run(COMMANDS.setCookiesCmd, {
        args: [action.payload.selectedCookies, action.payload.$cookies],
        successActionCreator: ACTIONS.successfullySetCookies,
      }));
    }

    case ACTION_TYPES.SUCCESSFULLY_SET_COOKIES: {
      return loop({
        ...state,
        ...action.payload,
        cookiesModal: null,
      },
      Cmd.list([
        Cmd.run(ANALYTICS_COMMANDS.checkRejectedTracking, {
          args: [state.analytics, action.payload.analyticCookiesAccepted],
        }),
        Cmd.run(COMMANDS.closeCookiesModalCmd, {
          args: [state.cookiesModal],
        }),
      ]));
    }

    default:
      return state;
  }
};

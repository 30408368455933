import * as ACTIONS from '../../reduxLoop/actions/contentActions';

class wsInformation {
  constructor($scope, ngMeta, $ngRedux, postMessageService, $state, routerService, Analytics) {
    'ngInject';

    this.$scope = $scope;
    this.ngMeta = ngMeta;
    this.$ngRedux = $ngRedux;
    this.openGraphTagsUpdated = false;
    this.postMessageService = postMessageService;
    this.$state = $state;
    this.routerService = routerService;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.informationPage.metatags) {
        this.$scope.$emit('dynamicMetaDataUpdated', state.vm.informationPage.metatags);
        this.openGraphTagsUpdated = true;
        const { title: ngMetaTitle } = state.vm.informationPage.metatags;
        this.analytics.trackEvent('view_page', { page_category_1: 'home', page_category_2: state.vm.informationPage.title?.toLowerCase() || undefined, page_title: ngMetaTitle?.toLowerCase() || undefined });
      }

      if (state.vm.informationPage.date === ''
          && !this.routerService.isPreview()
      ) {
        this.$state.go('404');
      }

      this.postMessageService.notifyRedactiePageLoaded();

      return {
        title: state.vm.informationPage.title,
        description: state.vm.informationPage.description,
        coverImage: state.vm.informationPage.coverImage,
        items: state.vm.informationPage.firstLevelItems,
        loading: state.vm.informationPage.loading,
      };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));
    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./wsInformation.html'),
  controllerAs: 'ctrl',
  controller: wsInformation,
  bindings: {
    sSource: '<',
  },
};

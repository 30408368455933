import * as CONTENT_ACTIONS from '../../reduxLoop/actions/contentActions';
import * as NEWS_ACTIONS from '../../reduxLoop/actions/newsActions';

require('./wsNewsDetail.scss');

class wsNewsDetail {
  constructor(
    $scope,
    $element,
    ngMeta,
    $sce,
    $ngRedux,
    $state,
    routerService,
    postMessageService,
    Analytics,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$state = $state;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
    this.openGraphTagsUpdated = false;
    this.$state = $state;
    this.routerService = routerService;
    this.postMessageService = postMessageService;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.newsPage.metatags) {
        this.$scope.$emit('dynamicMetaDataUpdated', state.vm.newsPage.metatags);
        this.openGraphTagsUpdated = true;
        const { title: ngMetaTitle } = state.vm.newsPage.metatags;
        this.analytics.trackEvent('view_page', {
          page_category_1: 'home',
          page_category_2: 'nieuws',
          page_category_3: state.vm.newsPage.title?.toLowerCase() || undefined,
          page_title: ngMetaTitle?.toLowerCase() || undefined,
        });
      }

      if (
        ['vacature', 'nieuwsbericht', 'persbericht'].includes(
          state.vm.newsPage.category,
        )
        && state.vm.newsPage.date === ''
        && !this.routerService.isPreview()
      ) {
        this.$state.go('404');
      }

      this.postMessageService.notifyRedactiePageLoaded();

      return {
        key: state.vm.newsPage.key,
        title: state.vm.newsPage.title,
        description: state.vm.newsPage.description,
        category: state.vm.newsPage.category,
        date: state.vm.newsPage.date,
        coverImage: state.vm.newsPage.coverImage,
        contentItems: state.vm.newsPage.firstLevelItems,
        previousItemPath: state.vm.newsPage.previousItemPath,
        nextItemPath: state.vm.newsPage.nextItemPath,
        loading: state.vm.newsPage.loading,
      };
    })(this);

    this.$ngRedux.dispatch(CONTENT_ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  backToOverview() {
    this.$ngRedux.dispatch(NEWS_ACTIONS.goToNewsOverview());
  }
}

export default {
  template: require('./wsNewsDetail.html'),
  controllerAs: 'ctrl',
  controller: wsNewsDetail,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};

class ws500 {
  constructor(Analytics) {
    'ngInject';

    this.analytics = Analytics;
  }

  $onInit() {
    this.analytics.trackEvent('view_page', { page_title: '500' }, 500);
  }
}

module.exports = {
  template: require('./ws500.html'),
  controllerAs: 'ctrl',
  controller: ws500,
  bindings: {},
};

export const getWebpageInfoFromHref = (sourceHref, webconfigurations) => {
  try {
    const cachedVersion = webconfigurations.find(wc => wc.source.href === sourceHref);
    if (cachedVersion) {
      return cachedVersion;
    }
    return undefined;
  } catch (e) {
    throw e;
  }
};

export const getCurrentPageWebConfiguration = (state) => {
  const currentSourceHref = state.router.currentParams
    ? state.router.currentParams.source
    : undefined;
  if (currentSourceHref) {
    const webconfig = getWebpageInfoFromHref(currentSourceHref, state.configurations.webpages);
    return webconfig;
  }
  return undefined;
};

export const getCurrentPageWebConfigurationType = (state) => {
  const webconfig = getCurrentPageWebConfiguration(state);
  return webconfig ? webconfig.template.$$expanded.code : undefined;
};

export const getStructuredDocumentRoot = items => items.find(i => i.type === 'STRUCTURED_DOCUMENT');

export const isPageOfType = (type, state) => getCurrentPageWebConfigurationType(state) === type;

export const isItemWithTemplateType = (item, templateType, webconfigurations) => {
  const webconfig = getWebpageInfoFromHref(item.$$meta.permalink, webconfigurations);
  return webconfig ? webconfig.template.$$expanded.code === templateType : false;
};

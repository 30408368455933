
export const INIT = 'init';
export const FETCH_USER = 'fetch_user';
export const USER_FETCHED = 'user_fetched';

export const STATE_CHANGE_SUCCESS = '@@reduxUiRouter/onSuccess';

export const LOAD_HOME = 'load_home';
export const SET_HOME_REFERENCES = 'set_home_references';
export const SET_HOME_NEWS = 'set_home_news';

export const LOAD_WEBCONFIGURATIONS = 'load_webconfigurations';
export const SET_WEBCONFIGURATIONS = 'set_webconfigurations';
export const INIT_STATIC_PAGE = 'init_static_page';
export const SET_STATIC_PAGE = 'set_static_page';
export const LOAD_CONTENT_TREE = 'load_content_tree';
export const SET_CONTENT_TREE = 'set_content_tree';
export const LOAD_NEWS = 'load_news';
export const SET_NEWS = 'set_news';
export const LOAD_MORE_NEWS = 'load_more_news';
export const UPDATE_NEWS_FILTER = 'update_news_filter';
export const CLEAR_NEWS_FILTER = 'clear_news_filter';
export const GO_TO_NEWS_OVERVIEW = 'go_to_news_overview';
export const GO_TO_NEXT_NEWS = 'go_to_next_news';
export const GO_TO_PREVIOUS_NEWS = 'go_to_previous_news';

export const EXTERNAL_REDIRECT = 'external_redirect';

export const INIT_SITE_COOKIES = 'init_site_cookies_modal';
export const SHOW_COOKIES_MODAL = 'show_cookies_modal';
export const SET_COOKIES_MODAL = 'set_cookies_modal';
export const CLOSE_COOKIES_MODAL = 'close_cookies_modal';
export const SET_COOKIES = 'set_cookies';
export const SUCCESSFULLY_SET_COOKIES = 'successfully_set_cookies';
export const LOADED_COOKIES = 'loaded_cookies';

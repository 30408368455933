require('./wsContentBlockParagraph.scss');

class wsContentBlockParagraph {
  constructor($element) {
    'ngInject';

    this.$element = $element;
  }

  $onInit() {
    this.$element.append(`<style>a.theme-content-link {background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(${this.sColor}, ${this.sColor}) !important; background-size: .05em 1px,.05em 1px,1px 1px !important; background-repeat: no-repeat,no-repeat,repeat-x !important; background-position: 0 95%,100% 95%,0 95% !important;} a.theme-content-link:hover {background: none !important; color: ${this.sColor} !important;}</style>`);
    this.$element.append(`<style>ws-term a:hover {color: ${this.sColor} !important;} ws-term .theme-content-begrip:hover:after {color: ${this.sColor} !important;}</style>`);
  }
}

module.exports = {
  template: require('./wsContentBlockParagraph.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockParagraph,
  bindings: {
    sItem: '<',
    sColor: '<',
    sLevel: '<',
  },
};

import { createStore, compose, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { install } from 'redux-loop';
import LogRocket from 'logrocket';
import routerMiddleware from 'redux-ui-router/src/router-middleware';
import { rootReducer } from './reducers';
import * as ACTION_TYPES from './constants/actionTypes';

let store = null;

// Create a store
export const createAppStore = ($stateProvider) => {
  const logger = createLogger({});

  const enhancer = compose(
    install(),
    // eslint-disable-next-line no-underscore-dangle
    applyMiddleware(routerMiddleware($stateProvider.$get())),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : v => v,
    applyMiddleware(LogRocket.reduxMiddleware()),
    // applyMiddleware('ngUiRouterMiddleware'),
  );

  store = createStore(rootReducer, enhancer);
  // Start with an INIT action
  store.dispatch({ type: ACTION_TYPES.INIT });

  return store;
};

export const getStore = () => store;

require('./ws404.scss');

class ws404 {
  constructor($scope, $location, $window, settings, Analytics) {
    'ngInject';

    this.$location = $location;
    this.$scope = $scope;
    this.$window = $window;
    this.settings = settings;
    this.analytics = Analytics;
  }

  $onInit() {
    this.analytics.trackEvent('view_page', { page_title: '404' }, 404);
    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  goToHome() {
    this.$location.path('/');
  }

  goToPro() {
    this.$window.location = this.settings.apisAndUrls.pro;
  }
}

module.exports = {
  template: require('./ws404.html'),
  controllerAs: 'ctrl',
  controller: ws404,
};

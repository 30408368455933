class wsContentBlockImage {
  constructor(imageGalleryService) {
    'ngInject';

    this.imageGalleryService = imageGalleryService;
  }

  openImageBox() {
    this.imageGalleryService.openLightbox([this.sItem.imageM.href], 0);
  }
}


module.exports = {
  template: require('./wsContentBlockImage.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockImage,
  bindings: {
    sItem: '<',
    sLevel: '<',
    sColor: '<',
  },
};

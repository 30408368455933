
const service = (fancyboxService) => {
  'ngInject';
  'use strict';

  const that = {};

  that.openLightbox = function (imageGalleryHrefs, currentImagePosition) {
    fancyboxService.fancyboxPlus()(
      imageGalleryHrefs,
      {
        index: currentImagePosition,
        type: 'image',
        changeFade: 0,
        cyclic: true,
        changeSpeed: 0,
        showCloseButton: false,
      },
    );
  };

  return that;
};

angular.module('services').factory('imageGalleryService', service);

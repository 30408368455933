/* eslint-disable max-len */
import { WWW_HOME_PAGE, NEWS_TAGS, WWW_CURRENT_THEME_PAGE } from '../static/constants';
import { isPageOfType, isItemWithTemplateType } from '../helpers/themaHelpers';
import contentUtils from '../../utils/contentUtils';
import { getNewsPath, itemVM } from '../helpers/newsHelpers';
import { sortHomeNews } from '../helpers/homeHelpers';
import { getMetatags } from '../helpers/helpers';

// for the moment the first referenced news will be the pinned news
function pinnedNews(references = [], webconfigurations) {
  const referencedNews = references.filter(reference => reference.tags.some(t => NEWS_TAGS.includes(t)));

  return referencedNews.length ? {
    key: referencedNews[0].key,
    title: referencedNews[0].title,
    description: referencedNews[0].description,
    path: getNewsPath(referencedNews[0], webconfigurations),
    coverImage: contentUtils.getImage(referencedNews[0], 'COVER_IMAGE', {}).href,
  } : undefined;
}

// find those references that are current themes using the webconfigurations to know it
function currentThemes(references = [], webconfigurations) {
  const referencedThemes = references.filter(reference => isItemWithTemplateType(reference, WWW_CURRENT_THEME_PAGE, webconfigurations));

  return referencedThemes.map(reference => ({
    title: reference.title,
    description: reference.description,
    thumbnailImage: contentUtils.getImage(reference, 'THUMBNAIL', {}).href,
    path: getNewsPath(reference, webconfigurations),
  }));
}

function createNewsVM(news, pinned, webconfigurations) {
  return sortHomeNews(news
    .filter(item => !pinned || item.$$meta.permalink !== `/content/${pinned.key}`)
    .map(item => itemVM(item, webconfigurations)));
}

export default (state) => {
  if (!state.home.items.length || !isPageOfType(WWW_HOME_PAGE, state)) {
    return {
      loading: true,
    };
  }

  const pinned = pinnedNews(state.home.references, state.configurations.webpages);
  const news = createNewsVM(state.home.news, pinned, state.configurations.webpages);
  const homeRoot = state.home.items.find(i => i.type === 'STRUCTURED_DOCUMENT');

  return {
    coverImage: contentUtils.getImage(homeRoot, 'COVER_IMAGE', {}).href,
    pinnedNews: pinned,
    bigNews: news.slice(0, 3),
    smallNews: news.slice(3, 7),
    themes: currentThemes(state.home.references, state.configurations.webpages),
    loading: false,
    loadingNews: !news.length,
    metatags: getMetatags(state),
  };
};

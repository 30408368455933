import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import utils from '../../utils/utils';
import constants from '../../components/constants';
import { showCookiesModal } from '../../reduxLoop/actions/cookiesActions';

class wsCookies {
  constructor($scope, ngMeta, $location, $ngRedux, $uibModal, $cookies, Analytics) {
    'ngInject';

    this.$scope = $scope;
    this.ngMeta = ngMeta;
    this.$location = $location;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.$cookies = $cookies;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (this.$location.$$hash && !state.vm.cookiesPage.loading) {
        this.scrollTo(constants.COOKIE_CONTENT[this.$location.$$hash]);
      }

      return {
        title: state.vm.cookiesPage.title,
        description: state.vm.cookiesPage.description,
        coverImage: state.vm.cookiesPage.coverImage,
        items: state.vm.cookiesPage.firstLevelItems,
        loading: state.vm.cookiesPage.loading,
      };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));
    this.$scope.$emit('showCompactHeader', true);
    this.analytics.trackEvent('view_page', { page_category_1: 'home', page_category_2: 'cookies', page_title: 'cookies' });
    this.$scope.$on('$destroy', () => {
      this.$scope.$emit('leaveCookies', false);
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }

  scrollTo(key) {
    setTimeout(() => {
      utils.scrollTo(key);
    }, 500);
  }

  openCookieModal() {
    this.$ngRedux.dispatch(showCookiesModal(this.$uibModal, this.$cookies));
  }
}

export default {
  template: require('./wsCookies.html'),
  controllerAs: 'ctrl',
  controller: wsCookies,
  bindings: {
    sSource: '<',
  },
};

import * as ACTION_TYPES from '../constants/actionTypes';

export const loadWebconfigurations = () => ({
  type: ACTION_TYPES.LOAD_WEBCONFIGURATIONS,
});

export const setWebconfigurations = results => ({
  type: ACTION_TYPES.SET_WEBCONFIGURATIONS,
  payload: results,
});

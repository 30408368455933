import * as ACTIONS from '../../reduxLoop/actions/contentActions';
import utils from '../../utils/utils';
import settings from '../../config/settings';

class wsThemeDetail {
  constructor(
    $scope,
    $element,
    ngMeta,
    $sce,
    $ngRedux,
    $state,
    routerService,
    postMessageService,
    Analytics,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;

    this.defaultProUrl = settings.apisAndUrls.pro;
    this.openGraphTagsUpdated = false;
    this.$state = $state;
    this.routerService = routerService;
    this.postMessageService = postMessageService;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.themePage.metatags) {
        this.$scope.$emit(
          'dynamicMetaDataUpdated',
          state.vm.themePage.metatags,
        );
        this.openGraphTagsUpdated = true;
        this.analytics.trackEvent('view_page', {
          page_category_1: 'home',
          page_category_2: 'themaoverzicht',
          page_category_3: state.vm.themePage.title?.toLowerCase(),
          page_title: state.vm.themePage.title?.toLowerCase(),
        });
      }

      if (state.vm.themePage.date === '' && !this.routerService.isPreview()) {
        this.$state.go('404');
      }

      this.postMessageService.notifyRedactiePageLoaded();

      return {
        themaTitle: state.vm.themePage.title,
        themaCoverImage: state.vm.themePage.coverImage,
        tocSections: state.vm.themePage.tocSections,
        recapSection: state.vm.themePage.recapSection,
        visionSection: state.vm.themePage.visionSection,
        explanationSection: state.vm.themePage.explanationSection,
        proUrl: state.vm.themePage.proUrl || this.defaultProUrl,
        loading: state.vm.themePage.loading,
      };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.sSource));

    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }

  scrollTo(key) {
    utils.scrollTo(key);
  }
}

export default {
  template: require('./wsThemeDetail.html'),
  controllerAs: 'ctrl',
  controller: wsThemeDetail,
  bindings: {
    sSource: '<',
    sSourceParam: '<',
  },
};

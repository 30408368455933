/* eslint-disable max-len */
// import moment from 'moment';
import { itemVM } from '../helpers/newsHelpers';
import utils from '../../utils/utils';
import { NEWS_PER_PAGE, HOMEPAGE_COVER_IMAGE } from '../static/constants';

function createItemsVM(items = [], webconfigurations) {
  return items.filter(item => item.issued).map(item => itemVM(item, webconfigurations));
}

function vmFilter(state) {
  const from = new Date(`${state.router.currentParams.van}T12:00`);
  const to = new Date(`${state.router.currentParams.tot}T12:00`);
  // moment.utc(state.router.currentParams.van).format()
  return {
    ...state.router.currentParams,
    van: state.router.currentParams.van ? from : undefined,
    tot: state.router.currentParams.tot ? to : undefined,
  };
}

export default (state) => {
  const allitems = utils.sortByIssuedDate(createItemsVM(state.news.items, state.configurations.webpages));
  const displayedItemsCount = NEWS_PER_PAGE * state.news.page;
  return {
    filter: vmFilter(state),
    allitems,
    itemsToDisplay: allitems.slice(0, displayedItemsCount),
    loading: !state.news,
    metatags: {
      url: 'https://www.katholiekonderwijs.vlaanderen/',
      title: 'Nieuwsberichten en vacatures',
      image: HOMEPAGE_COVER_IMAGE,
    },
    firstLoadOfPage: !state.news.userTriggeredSearch,
  };
};

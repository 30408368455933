class wsContentBlockImageGroup {
  constructor(imageGalleryService) {
    'ngInject';

    this.imageGalleryService = imageGalleryService;
  }

  $onInit() {
    function isEven(n) {
      return n % 2 === 0;
    }

    this.even = isEven(this.sItem.children.length);
  }

  openImageGallery(item) {
    const imageGalleryHrefs = this.sItem.children.map(image => image.imageM.href);
    const imagePosition = this.sItem.children.findIndex(image => image.key === item.key);
    this.imageGalleryService.openLightbox(imageGalleryHrefs, imagePosition);
  }
}

module.exports = {
  template: require('./wsContentBlockImageGroup.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockImageGroup,
  bindings: {
    sItem: '<',
    sLevel: '<',
    sColor: '<',
  },
};

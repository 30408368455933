import { WWW_HARD_CODED } from '../static/constants';
import { getCurrentPageWebConfigurationType } from '../helpers/themaHelpers';
import { getStaticHtml } from '../helpers/staticContentHelpers';
import { getMetatags } from '../helpers/helpers';

function isStaticPage(state) {
  return getCurrentPageWebConfigurationType(state) === WWW_HARD_CODED;
}

export default (tree, state) => {
  if (!tree || !isStaticPage(state)) {
    return {
      loading: true,
    };
  }

  return {
    title: tree.title,
    coverImage: tree.coverImage.href,
    html: getStaticHtml(tree),
    loading: false,
    metatags: getMetatags(state, tree),
  };
};

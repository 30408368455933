require('./wsNewsItem.scss');

class wsNewsItem {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./wsNewsItem.html'),
  controllerAs: 'ctrl',
  controller: wsNewsItem,
  bindings: {
    sItem: '<',
  },
};

require('./wsLoader.scss');

class wsLoader {
  constructor($scope, settings) {
    'ngInject';

    this.$scope = $scope;
    this.settings = settings;
  }

  $onInit() {
    this.sColor = this.sColor ? this.sColor : '#B72A8D';
  }

  $onDestroy() {
    // this.$scope.$emit('showCompactHeader', false);
  }
}

module.exports = {
  template: require('./wsLoader.html'),
  controllerAs: 'ctrl',
  controller: wsLoader,
  bindings: {
    sWidth: '@',
    sHeight: '@',
    sColor: '<?',
    sShowHeader: '<?',
    sShowCompactHeader: '<?',
  },
};

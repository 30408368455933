class wsNoAccess {
  constructor(Analytics) {
    'ngInject';

    this.analytics = Analytics;
  }

  $onInit() {
    this.analytics.trackEvent('view_page', { page_access: 'denied', page_title: 'access denied' }, 403);
  }
}

module.exports = {
  template: require('./wsNoAccess.html'),
  controllerAs: 'ctrl',
  controller: wsNoAccess,
  bindings: {},
};

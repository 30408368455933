// This contains most code for supporting OAuth, translations, and remote logging.

module.exports = ($provide, $stateProvider, $urlRouterProvider, cfpLoadingBarProvider,
  ngMetaProvider, $locationProvider, settings, $ngReduxProvider) => {
  'ngInject';

  require('./vskoconfig').default($provide);
  require('./routes').default($stateProvider, $urlRouterProvider, $locationProvider, ngMetaProvider, $ngReduxProvider);

  cfpLoadingBarProvider.includeBar = false;
  cfpLoadingBarProvider.includeSpinner = false;
};

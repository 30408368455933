import * as ACTION_TYPES from '../constants/actionTypes';

export const loadNews = params => ({
  type: ACTION_TYPES.LOAD_NEWS,
  payload: params,
});

export const setNews = result => ({
  type: ACTION_TYPES.SET_NEWS,
  payload: result,
});

export const loadMoreNews = result => ({
  type: ACTION_TYPES.LOAD_MORE_NEWS,
  payload: result,
});

export const updateNewsFilter = filter => ({
  type: ACTION_TYPES.UPDATE_NEWS_FILTER,
  payload: filter,
});

export const clearNewsFilter = () => ({
  type: ACTION_TYPES.CLEAR_NEWS_FILTER,
});

export const goToNewsOverview = () => ({
  type: ACTION_TYPES.GO_TO_NEWS_OVERVIEW,
});

export const goToNextNews = key => ({
  type: ACTION_TYPES.GO_TO_NEXT_NEWS,
  payload: key,
});

export const goToPreviousNews = key => ({
  type: ACTION_TYPES.GO_TO_PREVIOUS_NEWS,
  payload: key,
});

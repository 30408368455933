/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { COOKIE_TYPE } from '../static/constants';
import { isAnalyticCookiesAccepted, isSocialCookiesAccepted, setCookie } from '../helpers/cookiesHelpers';

export const showCookiesModalCmd = ($uibModal, $cookies) => {
  const modal = $uibModal.open({
    animation: true,
    component: 'wsCookieConsent',
    size: 'md',
    backdrop: 'static',
    backdropClass: 'cookies-backdrop',
    windowClass: 'cookies-modal',
    resolve: {
      selected: () => ({
        [COOKIE_TYPE.ANALYTIC]: isAnalyticCookiesAccepted($cookies),
        [COOKIE_TYPE.SOCIAL]: isSocialCookiesAccepted($cookies),
      }),
    },
  });
  return modal;
};

export const closeCookiesModalCmd = (modal) => {
  modal.close();
};

export const loadCookiesCmd = $cookies => ({
  analyticCookiesAccepted: isAnalyticCookiesAccepted($cookies),
  socialCookiesAccepted: isSocialCookiesAccepted($cookies),
});

export const setCookiesCmd = (selectedCookies, $cookies) => {
  Object.keys(selectedCookies).forEach((cookie) => {
    setCookie(cookie, selectedCookies[cookie], $cookies);
  });
  return {
    analyticCookiesAccepted: isAnalyticCookiesAccepted($cookies),
    socialCookiesAccepted: isSocialCookiesAccepted($cookies),
  };
};

import { loop, combineReducers } from 'redux-loop';
import { router } from 'redux-ui-router';
import viewModel from '../viewModel';
import contentReducer from './contentReducer';
import newsReducer from './newsReducer';
import homeReducer from './homeReducer';
import configurationsReducer from './configurationsReducer';
import dynamicTransitionReducer from './dynamicTransitionReducer';
import cookiesReducer from './cookiesReducer';

export const arrayToMap = (array) => {
  const ret = {};
  array.forEach((item) => {
    ret[item.$$meta.permalink] = item;
  });
  return ret;
};

export const rootReducer = (state, action) => {
  // console.info('ACTION', action.type, action);

  const rawStateReducer = combineReducers({
    router,
    dynamicTransition: dynamicTransitionReducer,
    configurations: configurationsReducer,
    news: newsReducer,
    home: homeReducer,
    content: contentReducer,
    cookies: cookiesReducer,
  });

  const newLoop = rawStateReducer(state, action, state);
  const newState = newLoop[0];
  const vmState = { ...newState, vm: viewModel(newState) };

  console.log(`STATE after ${action.type}`, vmState);
  return loop(vmState, newLoop[1]);
};

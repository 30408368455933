class wsContentBlockTitle {
  constructor() {
    'ngInject';
  }
}

module.exports = {
  template: require('./wsContentBlockTitle.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockTitle,
  bindings: {
    sItem: '<',
    sLevel: '<',
    sColor: '<',
    sClass: '<',
    sHideAttachments: '<',
  },
};

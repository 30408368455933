/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import { stateReload } from 'redux-ui-router';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/configurationsCommands';
import * as ACTIONS from '../actions/configurationsActions';

const initialState = {
  webpages: [],
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.INIT: {
      return loop(
        state,
        Cmd.list([
          Cmd.action(ACTIONS.loadWebconfigurations()),
        ]),
      );
    }

    case ACTION_TYPES.LOAD_WEBCONFIGURATIONS: {
      return loop({
        ...state,
      },
      Cmd.run(COMMANDS.loadWebconfigurations, {
        args: [],
        successActionCreator: ACTIONS.setWebconfigurations,
        failActionCreator: error => console.log('ERROR loading webconfigs:', error),
      }));
    }

    case ACTION_TYPES.SET_WEBCONFIGURATIONS: {
      return loop({
        ...state,
        webpages: action.payload,
      }, Cmd.action(stateReload()));
    }

    default:
      return state;
  }
};

/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import { stateTransitionTo } from 'redux-ui-router';
import * as ACTION_TYPES from '../constants/actionTypes';
import { TRANSITIONING_STATE } from '../static/constants';
import { getStateToGo } from '../helpers/dynamicTransitionHelpers';

const initialState = {
};

export default (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.STATE_CHANGE_SUCCESS: {
      let loopCmd = Cmd.none;
      let newStateToGo;

      if (rootState.router.currentState.name === TRANSITIONING_STATE && rootState.configurations.webpages.length) {
        // try to find the current path in webconfigs and if exists do a transition to the correspodning state that should handle it
        const targetedPath = rootState.router.currentParams.pathToResolve;
        const webconfiguration = rootState.configurations.webpages.find(wc => wc.path === targetedPath || wc.source.href === targetedPath);

        // console.log('Custom STATE_CHANGE_SUCCESS routerState:', rootState.router);

        newStateToGo = getStateToGo(webconfiguration);

        if (newStateToGo) {
          loopCmd = Cmd.action(stateTransitionTo(newStateToGo.name, newStateToGo.params, { location: false }));
        } else {
          loopCmd = Cmd.action(stateTransitionTo('404'));
        }
      }

      return loop({
        ...state,
        redirectedToState: newStateToGo || state.redirectedToState,
      }, loopCmd);
    }

    default:
      return state;
  }
};

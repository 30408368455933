import { WWW_INFORMATION_PAGE } from '../static/constants';
import { getCurrentPageWebConfigurationType } from '../helpers/themaHelpers';
import { getMetatags } from '../helpers/helpers';
import utils from '../../utils/utils';

export default (tree, state) => {
  if (!tree || getCurrentPageWebConfigurationType(state) !== WWW_INFORMATION_PAGE) {
    return {
      loading: true,
    };
  }

  return {
    title: tree.title,
    description: tree.description,
    coverImage: tree.coverImage.href,
    firstLevelItems: tree ? tree.children : [],
    loading: !tree,
    metatags: getMetatags(state, tree),
    date: utils.getFormattedDate(tree.issued),
  };
};

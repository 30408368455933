class wsContentBlockDeepLinkTooltip {
  constructor($element, $scope, $rootScope, $location, routerService) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.routerService = routerService;
  }

  $onInit() {
    this.deepLinkCopied = false;
    this.anchor = this.routerService.generateHash(this.sKey);
  }

  copyDeepLink() {
    const url = new URL(this.$location.absUrl());
    url.searchParams.set('scrollTo', this.anchor);
    console.log(url.toString());
    const textArea = document.createElement('textarea');
    textArea.value = url.toString();
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    this.deepLinkCopied = true;
  }

  showTooltip() {
    this.deepLinkCopied = false;
    const tooltip = this.$element.find('.kov-pro-tooltip');
    tooltip.addClass('show');
  }
}

module.exports = {
  template: require('./wsContentBlockDeepLinkTooltip.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockDeepLinkTooltip,
  bindings: {
    sKey: '<',
    sColor: '<',
  },
};

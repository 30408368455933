/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import { stateTransitionTo } from 'redux-ui-router';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/newsCommands';
import * as ACTIONS from '../actions/newsActions';
import {
  buildFilter, getNewsPath, getIssuedNewsitems, getNextNewsitem, getPreviousNewsitem,
} from '../helpers/newsHelpers';
import utils from '../../utils/utils';

const initialState = {
  reloadTriggerdByWebConfigurationReducer: false,
  items: [],
  page: 1,
  filter: {},
  userTriggeredSearch: false,
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.SET_WEBCONFIGURATIONS: {
      // this is a hack because triggering search happens in de wsNewsOverviewComponent which calls loadNews on initialisation
      // the www is depending on state transitions which then triggers the $onInit->loadNews
      // also setting webConfiguration is triggering a state reload
      // but that then triggers a new search call which could be unnecessary
      // therefore we need to keep track if configurationReducer did this reload to avoid searching twice.
      return { ...state, reloadTriggerdByWebConfigurationReducer: true, userTriggeredSearch: true };
    }

    case ACTION_TYPES.LOAD_NEWS: {
      const filter = buildFilter(action.payload);
      if (state.reloadTriggerdByWebConfigurationReducer && state.items.length > 0) {
        return { ...state, filter, reloadTriggerdByWebConfigurationReducer: false, userTriggeredSearch:false  };
      }

      return loop({
        ...state,
        filter,
        reloadTriggerdByWebConfigurationReducer: false,
        userTriggeredSearch: false
      },
      Cmd.run(COMMANDS.loadNews, {
        args: [{ ...rootState.router.currentParams }],
        successActionCreator: ACTIONS.setNews,
        failActionCreator: error => console.log('ERROR loading content tree:', error),
      }));
    }

    case ACTION_TYPES.SET_NEWS: {
      return {
        ...state,
        items: utils.sortByIssuedDate(getIssuedNewsitems(action.payload)),
      };
    }

    case ACTION_TYPES.LOAD_MORE_NEWS: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case ACTION_TYPES.UPDATE_NEWS_FILTER: {
      const filter = buildFilter(action.payload);

      return loop({
        ...state,
        filter,
        userTriggeredSearch: true,
      },
      Cmd.action(stateTransitionTo('newsDatabank', filter, { location: 'replace' })));
    }

    case ACTION_TYPES.CLEAR_NEWS_FILTER: {
      return loop({
        ...state,
        userTriggeredSearch: true,
      },
      Cmd.action(stateTransitionTo('newsDatabank', {}, { location: 'replace' })));
    }

    case ACTION_TYPES.GO_TO_NEWS_OVERVIEW: {
      return loop({
        ...state,
        userTriggeredSearch: false,
      },
      Cmd.action(stateTransitionTo('newsDatabank', state.filter, { location: true })));
    }

    case ACTION_TYPES.GO_TO_NEXT_NEWS: {
      const news = getNextNewsitem(action.payload, state.items);
      return loop({
        ...state,
      },
      news ? Cmd.action(stateTransitionTo('transitioning', {
        pathToResolve: getNewsPath(news, rootState.configurations.webpages),
      }, { location: true })) : Cmd.none);
    }

    case ACTION_TYPES.GO_TO_PREVIOUS_NEWS: {
      const news = getPreviousNewsitem(action.payload, state.items);
      return loop({
        ...state,
      },
      news ? Cmd.action(stateTransitionTo('transitioning', {
        pathToResolve: getNewsPath(news, rootState.configurations.webpages),
      }, { location: true })) : Cmd.none);
    }

    default:
      return state;
  }
};

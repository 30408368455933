/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */

import { getMenuContent } from '../helpers/helpers';
import { getStructuredDocumentRoot } from '../helpers/themaHelpers';
import {
  HEADER_MAIN_MENU_KEY, HEADER_MOBILE_MENU_KEY, HEADER_OVERLAY_MENU_KEY, LANGUAGES_BY_PATH,
} from '../static/constants';

export default (state) => {
  let currentPage;
  let currentPath;
  const currentSource = state.router.currentParams ? state.router.currentParams.source : undefined;
  const rootItems = state.content.pages[currentSource];

  if (rootItems) {
    currentPage = getStructuredDocumentRoot(rootItems);
    currentPath = state.dynamicTransition.redirectedToState ? state.dynamicTransition.redirectedToState.path : undefined;
  }

  return {
    currentPageTitle: currentPage ? currentPage.title : undefined,
    language: LANGUAGES_BY_PATH[currentPath] ? LANGUAGES_BY_PATH[currentPath] : 'NL',
    mainMenu: getMenuContent(state, HEADER_MAIN_MENU_KEY),
    mobileMenu: getMenuContent(state, HEADER_MOBILE_MENU_KEY),
    overlayMenu: getMenuContent(state, HEADER_OVERLAY_MENU_KEY),
  };
};

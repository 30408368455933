class wsContentBlockDeepLink {
  constructor($element, $scope, $rootScope, $location, routerService) {
    'ngInject';

    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.routerService = routerService;
  }

  $onInit() {
    this.sLevel = this.sLevel || (this.sIsCollapsedBlock ? 4 : 5);
    this.anchor = this.routerService.generateHash(this.sKey);
  }

  hideTooltip() {
    const tooltip = this.$element.find('.kov-pro-tooltip');
    tooltip.removeClass('show');
  }
}

module.exports = {
  template: require('./wsContentBlockDeepLink.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockDeepLink,
  bindings: {
    sLevel: '<',
    sKey: '<',
    sTitle: '<',
    sColor: '<',
    sClass: '<',
    sIsCollapsedBlock: '<',
  },
};

/* eslint-disable max-len */
import staticPageModel from './viewModels/staticPageModel';
import themePageModel from './viewModels/themePageModel';
import informationPageModel from './viewModels/informationPageModel';
import newsOverviewModel from './viewModels/newsOverviewModel';
import newsPageModel from './viewModels/newsPageModel';
import headerModel from './viewModels/headerModel';
import footerModel from './viewModels/footerModel';
import homePageModel from './viewModels/homePageModel';
import cookiesModel from './viewModels/cookiesModel';
import cookiesPageModel from './viewModels/cookiesPageModel';
import { getStructuredDocumentRoot } from './helpers/themaHelpers';
import { buildTree } from '../module/contentTree';
import { getContentPageItems } from './helpers/helpers';

export default (state) => {
  const currentSource = state.router.currentParams ? state.router.currentParams.source : undefined;
  const items = getContentPageItems(currentSource, state);
  // build tree if current source is a structured document and is already loaded
  const tree = items && items.length ? buildTree(getStructuredDocumentRoot(items).key, items, state.configurations.webpages) : undefined;

  return {
    header: headerModel(state),
    footer: footerModel(state),
    homePage: homePageModel(state),
    staticPage: staticPageModel(tree, state),
    themePage: themePageModel(tree, state),
    informationPage: informationPageModel(tree, state),
    newsOverview: newsOverviewModel(state),
    newsPage: newsPageModel(tree, state),
    cookies: cookiesModel(state),
    cookiesPage: cookiesPageModel(tree, state),
  };
};

export default ($provide) => {
  // If we want to inject other modules services during the .config phase, we need to inject
  // them manually. The injector needs to know the dependencies ( 'ng' is for things like $log
  // and $http) see
  // clehttp://stackoverflow.com/questions/19719729/angularjs-injecting-factory-from-another-module-into-a-provider
  // and also http://stackoverflow.com/a/21536845

  $provide.decorator('$exceptionHandler', ['$delegate', $delegate => (exception, cause) => {
    if (console) {
      console.error(exception);
    }
    $delegate(exception, cause);
  }]);
};

import moment from 'moment';

const jsSearch = require('js-search');

export default {
  offsetFromTop: 85,
  elmYPosition(eID) {
    const elm = document.getElementById(eID);
    let position;
    if (!elm) {
      console.warn(`${eID} is not found`);
    } else {
      position = elm.offsetTop;
      let node = elm;
      while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        position += node.offsetTop;
      }
    }
    return position;
  },
  elmYEndPosition(eID) {
    const elm = document.getElementById(eID);
    let position;
    if (!elm) {
      console.warn(`${eID} is not found`);
    } else {
      position = elm.offsetTop + elm.offsetHeight;
      let node = elm;
      while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        position += node.offsetTop;
      }
    }
    return position;
  },
  scrollTo(key) {
    window.scrollTo(0, this.elmYPosition(key) - this.offsetFromTop + 1);
  },
  array_move(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k > 0) {
        k -= 1;
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  },

  arrayToMap(array) {
    return array.reduce((obj, item) => {
      obj[item.$$meta.permalink] = item;
      return obj;
    }, {});
  },

  flattenTreeToMap(tree, onlyVisible = true) {
    const flatMap = new Map();
    function addItems(items) {
      items.forEach((item) => {
        flatMap.set(item.href, item);
        if (item.children) {
          addItems(item.children.filter(child => !onlyVisible || child.visible));
        }
      });
    }

    if (tree && (!onlyVisible || tree.visible)) {
      flatMap.set(tree.href, tree);
      if (tree.children) {
        addItems(tree.children.filter(child => !onlyVisible || child.visible));
      }
    }
    return flatMap;
  },
  flattenTree(tree, onlyVisible = true) {
    const flatMap = [];
    function addItems(items) {
      items.forEach((item) => {
        flatMap.push(item);
        if (item.children) {
          addItems(item.children.filter(child => !onlyVisible || child.visible));
        }
      });
    }

    if (tree && (!onlyVisible || tree.visible)) {
      flatMap.push(tree);
      if (tree.children) {
        addItems(tree.children.filter(child => !onlyVisible || child.visible));
      }
    }
    return flatMap;
  },
  flattenTreeFromContentList(root, childrenMap) {
    const flatMap = [];
    function addItems(items) {
      items.forEach((item) => {
        flatMap.push(item);
        const isPartOfRelations = item.$$relationsTo.filter(o => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype));
        if (isPartOfRelations.length > 0) {
          addItems(isPartOfRelations.map(
            relation => childrenMap.get(relation.$$expanded.from.href),
          ));
        }
      });
    }

    if (root) {
      flatMap.push(root);
      const isPartOfRelations = root.$$relationsTo.filter(o => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype));
      if (isPartOfRelations.length > 0) {
        addItems(isPartOfRelations.map(relation => childrenMap.get(relation.$$expanded.from.href)));
      }
    }
    return flatMap;
  },

  groupChildItemsByRequirements(item) {
    const groupedItems = {};

    if (item.facets && item.facets.filter(o => o.component !== 'FULL_TEXT_SEARCH').length > 0) {
      item.children.forEach((child) => {
        child.requirements.forEach((requirementHref) => {
          if (!groupedItems[requirementHref]) {
            groupedItems[requirementHref] = [];
          }
          groupedItems[requirementHref].push(child);
        });
      });
    }
    return groupedItems;
  },

  filterItemsByRequirements(items, filter) {
    if (filter.array.length === 0) {
      return items;
    }
    return items.filter(item => item.requirements
      && item.requirements.some(requirement => filter.array.includes(requirement)));
  },

  filterItemsByKeyword(items, filter) {
    if (filter.searchIndex && filter.q) {
      return items.filter(item => filter.searchIndex.search(filter.q)
        .map(matchedItem => matchedItem.href).includes(item.href));
    }
    return items;
  },

  filterItems(items, filters) {
    return filters.reduce((filteredItems, filter) => {
      if (filter.type === 'REQUIREMENTS') {
        return module.exports.filterItemsByRequirements(filteredItems, filter);
      } if (filter.type === 'FULL_TEXT') {
        return module.exports.filterItemsByKeyword(filteredItems, filter);
      }
      return items;
    }, items);
  },

  async createIndexAsync(item) {
    const searchIndex = new jsSearch.Search('href');
    function addItemsToIndex(items, index, rootHref) {
      items.forEach((child) => {
        let rootHrefToPass;
        if (!rootHref) {
          rootHrefToPass = child.href;
        } else {
          rootHrefToPass = rootHref;
        }
        if (child.title) {
          index.addDocument({ href: rootHrefToPass, content: child.title });
        }
        if (child.description) {
          index.addDocument({ href: rootHrefToPass, content: child.description });
        }
        if (child.$$html) {
          index.addDocument({ href: rootHrefToPass, content: child.$$html });
        }
        if (child.children) {
          addItemsToIndex(child.children, index, rootHrefToPass);
        }
      });
    }

    return new Promise((resolve, reject) => {
      try {
        addItemsToIndex(item.children, searchIndex);
        searchIndex.addIndex(['content']);
        resolve(searchIndex);
      } catch (e) {
        reject(e);
        throw e;
      }
    });
  },

  hrefsUnder(curriculumManager, root) {
    root.$$hrefsUnder = [root.$$meta.permalink];
    if (root.$$relationsTo) {
      const itemsUnder = root.$$relationsTo
        .filter(o => o.$$expanded.relationtype === 'IS_PART_OF')
        .map(o => curriculumManager.getByHrefNoPromise(o.$$expanded.from.href));

      itemsUnder.forEach((item) => {
        if (item) {
          root.$$hrefsUnder.push(item.$$meta.permalink);
          root.$$hrefsUnder = [...root.$$hrefsUnder,
            ...module.exports.hrefsUnder(curriculumManager, item)];
        }
      });
    }
    return root.$$hrefsUnder;
  },

  computeLoadTimeout(allChildren) {
    let loadTimeout = 0;
    allChildren.forEach((child) => {
      if (child.type === 'IMAGE') {
        loadTimeout += 100;
      } else if (child.type === 'VIDEO') {
        loadTimeout += 200;
      } else if (child.type === 'ATTACHMENT') {
        loadTimeout += 50;
      } else if (child.type === 'PARAGRAPH') {
        loadTimeout += 5;
      }
    });

    return Math.max(loadTimeout, 600);
  },

  haveCommonElements(array1, array2) {
    const intersection = array1.filter(x => array2.includes(x));

    return intersection.length > 0;
  },

  getFormattedDate(date) {
    return date ? new Date(date).toLocaleDateString('nl-BE', {
      weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
    }) : '';
  },

  sixMonthsAgoDate() {
    return moment().subtract(6, 'months').format('YYYY-MM-DD');
  },

  sortByIssuedDate(items) {
    return items
      .filter(i => i.issued)
      .sort((i1, i2) => new Date(i2.issued.startDate) - new Date(i1.issued.startDate));
  },
};

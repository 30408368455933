import app from '../../app/app';

app.component('wsContentBlock', require('./wsContentBlock'));
app.component('wsContentBlockTitle', require('./wsContentBlockTitle'));
app.component('wsContentBlockParagraph', require('./wsContentBlockParagraph'));
app.component('wsContentBlockImage', require('./wsContentBlockImage'));
app.component('wsContentBlockImageGroup', require('./wsContentBlockImageGroup'));
app.component('wsContentBlockSummary', require('./wsContentBlockSummary'));
app.component('wsContentBlockLegal', require('./wsContentBlockLegal'));
app.component('wsContentBlockCollapsed', require('./wsContentBlockCollapsed'));
app.component('wsContentBlockQuote', require('./wsContentBlockQuote'));
app.component('wsContentBlockAttachments', require('./wsContentBlockAttachments'));
app.component('wsContentBlockVideo', require('./wsContentBlockVideo'));
app.component('wsContentBlockDeepLink', require('./wsContentBlockDeepLink'));
app.component('wsContentBlockDeepLinkTooltip', require('./wsContentBlockDeepLinkTooltip'));
app.component('wsContentBlockReferences', require('./wsContentBlockReferences'));
app.component('wsContentBlockExample', require('./wsContentBlockExample'));
app.component('wsContentBlockReferenceGroup', require('./wsContentBlockReferenceGroup'));
app.component('wsContentBlockReferenceItem', require('./wsContentBlockReferenceGroup/wsContentBlockReferenceItem'));

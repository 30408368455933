import * as ACTIONS from '../../reduxLoop/actions/homeActions';

require('./wsHome.scss');

class wsHome {
  constructor($scope, $element, $ngRedux, ngMeta, Analytics) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$ngRedux = $ngRedux;
    this.ngMeta = ngMeta;
    this.openGraphTagsUpdated = false;
    this.analytics = Analytics;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!this.openGraphTagsUpdated && state.vm.homePage.metatags) {
        this.$scope.$emit('dynamicMetaDataUpdated', state.vm.homePage.metatags);
        this.openGraphTagsUpdated = true;
        const { title: ngMetaTitle } = state.vm.homePage.metatags;
        this.analytics.trackEvent('view_page', { page_category_1: 'home', page_title: ngMetaTitle?.toLowerCase() || undefined });
      }
      return { ...state.vm.homePage };
    })(this);

    this.$ngRedux.dispatch(ACTIONS.loadHome(this.sSource));

    this.$scope.$emit('showHeader', true);
    this.$scope.$emit('showCompactHeader', false);
  }

  $onDestroy() {
    this.$scope.$emit('showHeader', false);
    this.unsubscribe();
  }

  itemBackgroundImage(item, field) {
    return item && item[field] ? { background: `url('${item[field]}')` } : undefined;
  }

  itemBackgroundCoverImage(item) {
    return this.itemBackgroundImage(item, 'coverImage');
  }

  itemBackgroundThumbnailImage(item) {
    return this.itemBackgroundImage(item, 'thumbnailImage');
  }
}

export default {
  template: require('./wsHome.html'),
  controllerAs: 'ctrl',
  controller: wsHome,
  bindings: {
    sSource: '<',
  },
};

class wsContentBlockQuote {
  constructor($element) {
    'ngInject';

    this.$element = $element;
  }

  $onInit() {
    this.$element.append(`<style>ws-content-block-quote .theme-content-blockquote::before {color: ${this.sColor} !important;}</style>`);
  }
}

module.exports = {
  template: require('./wsContentBlockQuote.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockQuote,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

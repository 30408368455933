/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/homeCommands';
import * as ACTIONS from '../actions/homeActions';

const initialState = {
  loadingReferences: true,
  loadingNews: true,
  items: [], // home structured_document items
  references: [],
  news: [],
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_HOME: {
      return loop({
        ...state,
      },
      state.loadingReferences
        ? Cmd.list([
          Cmd.run(COMMANDS.loadHomeReferences, {
            args: [action.payload],
            successActionCreator: ACTIONS.setHomeReferences,
            failActionCreator: error => console.log('ERROR loading home page references:', error),
          }),
          Cmd.run(COMMANDS.loadHomeNews, {
            successActionCreator: ACTIONS.setHomeNews,
            failActionCreator: error => console.log('ERROR loading home page news:', error),
          }),
        ]) : Cmd.none);
    }

    case ACTION_TYPES.SET_HOME_REFERENCES: {
      return {
        ...state,
        ...action.payload,
        loadingReferences: false,
      };
    }

    case ACTION_TYPES.SET_HOME_NEWS: {
      return {
        ...state,
        news: action.payload,
        loadingNews: false,
      };
    }

    default:
      return state;
  }
};

/**
 * This defines your own module for this application. If you have other dependencies
 * on other modules, add them here.
 *
 * Remark that we get the modulename from the window.vskoConfiguration object (see
 * config/constants.js), which makes it easier to update the modulename in one place !
 *
 * This configuration will be added to the application module as a constant also, so it can be
 * used 'the angular way' later on in your modules, controllers, directives (both the OAuth module
 * and the RemoteErrorLogger need some info from the 'configuration' ! )
 */

import ngReduxRouter from 'redux-ui-router';
import settings from '../config/settings';

require('../../css/framework/bootstrap.css');
require('../../css/style.scss');
require('../../css/custom.scss');

const angular = require('angular');

const basicConfig = {
  name: 'api',
  baseUrl: settings.apisAndUrls.api,
};

const legacyConfig = {
  name: 'sriClient',
  baseUrl: settings.apisAndUrls.cachedContentApi,
  caching: {
    timeout: 400,
  },
};

let modules = [];

if (settings.logging.sentry.enabled) {
  const Sentry = require('@sentry/browser');
  const Integrations = require('@sentry/integrations');

  Sentry.init({
    dsn: settings.logging.sentry.url,
    enabled: true,
    debug: false,
    release: settings.projectVersion,
    environment: settings.environment === '' ? 'PROD' : settings.environment,
    maxValueLength: 2000,
    integrations: [
      // We can't upgrade sentry to version 7 because of our Angular version:
      // Discontinued support for AngularJS 1.x
      // From version 7 onwards, the Sentry JavaScript SDK will not support AngularJS 1.x.
      // Please use version 6.x of the SDK if you want to use Sentry in combination with AngularJs 1.x
      // https://docs.sentry.io/platforms/javascript/guides/angular/angular1/
      new Integrations.Angular(),
    ],
    beforeSend(event) {
      const cookies = `; ${document.cookie}`.split('; ');
      const analytics = cookies.find(e => e.includes('cookies__analytic'));
      if (analytics) {
        const accepted = analytics.split('=').pop() === 'true';
        return accepted ? event : null;
      }

      return null;
    },
  });
  modules.push('ngSentry');
}

// Node Modules
require('angular-sanitize');
require('angular-loading-bar');
require('@uirouter/angularjs');
require('angular-cookies');
require('angular-md5');
require('ui-bootstrap4/src/modal');
require('ui-bootstrap4/src/dropdown/index-nocss');
require('ui-bootstrap4/src/progressbar');
require('angular-sticky-plugin');
require('../module/ion-range-slider-angularjs-1.1.3');
require('../module/nya-bootstrap-select-modified');
require('../module/nya-bootstrap-select-modified/dist/css/nya-bs-select.min.css');
require('../services');
require('ngstorage');
require('ng-meta');
require('@kathondvla/curriculum-manager');
require('angular-fancybox-plus/js/angular-fancybox-plus');
require('fancybox-plus/dist/jquery.fancybox-plus');
require('fancybox-plus/css/jquery.fancybox-plus.css');
require('moment');
require('angular-moment');

/* Used to make the search field focused when you click the top bar */
require('ng-focus-on');

// Redux
require('ng-redux');

// Custom Modules

modules = [...modules,
  'ng',
  'angular-loading-bar',
  'ui.router',
  'angular-md5',
  'ngCookies',
  'ui.bootstrap.module.modal',
  'ui.bootstrap.module.dropdown',
  'ui.bootstrap.module.progressbar',
  'ng-sri-client',
  'focusOn',
  'services',
  'hl.sticky',
  'ion.rangeslider',
  'nya.bootstrap.select',
  'ngStorage',
  'ngMeta',
  'curriculumManager',
  'fancyboxplus',
  'angularMoment',
  require('@kathondvla/sri-client/ng-sri-client')([basicConfig, legacyConfig]),
  'ngRedux',
  ngReduxRouter,
];

// create the module
const app = angular.module(
  'VskoApplication',
  modules,
);

app.service('$notification', [() => {
  const that = {};

  that.error = (message) => {
    console.error(message);
  };

  that.warning = (message) => {
    console.warn(message);
  };

  that.success = (message) => {
    console.log(message);
  };

  that.info = (message) => {
    console.log(message);
  };

  return that;
}]);

export default app;

/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { getNow } from '@kathondvla/sri-client/date-utils';
import { cachedContentApi, contentApi } from '../api/apiConfig';
import { getContentHash } from './contentCommands';
import { NEWS_IN_HOME_TYPES, WWW_SEARCH_TYPE } from '../static/constants';

export const loadHomeReferences = async (rootHref) => {
  const hash = await getContentHash(rootHref);
  const items = await cachedContentApi.getAll('/content', {
    root: rootHref,
  }, { headers: { 'VSKO-Resource-Hash': hash.hash } });

  const referencesHrefs = items.filter(item => item.type === 'REFERENCE').map((item) => {
    const referencesRelation = item.$$relationsFrom.find(relation => relation.$$expanded.relationtype === 'REFERENCES');
    return referencesRelation.$$expanded.to.href;
  });
  // we should be able to use cached api for this request and batch doesn't support it (use hrefs=... )
  // references shouldn't be much in home page and so will not exceed the max number
  const references = await cachedContentApi.getAll('/content', {
    hrefs: referencesHrefs.join(','),
  }, { headers: { 'VSKO-Resource-Hash': hash.hash } });

  return {
    items,
    references,
  };
};

export const loadHomeNews = async () => {
  // only 7 recent news items are shown in the homepage as tiles (3 big tiles at the top and 4 small ones below).
  // One news items is called pinned if it is linked to the homepage throught a REFERENCES relations.
  // Only one pinned news item is shown on the homepage on the top of big news items.
  // By retrieving the eight most recent news items already published from search api, we have enought items to
  // show as tiles in the homepages.
  const news = await contentApi.getRaw('/content', {
    tagsOverlaps: NEWS_IN_HOME_TYPES.join(','),
    orderBy: 'issued',
    issuedBefore: getNow(),
    descending: true,
    limit: 7,
  }, {});
  return news.results.map(item => item.$$expanded);
};

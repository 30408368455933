
class wsHardcodedTest {
  constructor($scope, $element, ngMeta) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.ngMeta = ngMeta;
  }

  $onInit() {
    this.$scope.$emit('showCompactHeader', true);
  }

  $onDestroy() {
    this.$scope.$emit('showCompactHeader', false);
  }
}

module.exports = {
  template: require('./wsHardcodedTest.html'),
  controllerAs: 'ctrl',
  controller: wsHardcodedTest,
  bindings: {
    // sMenu: '<',
  },
};

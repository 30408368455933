require('./wsBackToTop.scss');

class wsBackToTop {
    constructor($document, $window) {
        'ngInject';

        this.$document = $document;
        this.$window = $window;

        this.progressPath = document.querySelector('.progress-wrap path');
        this.pathLength = this.progressPath.getTotalLength();

        this.duration = 550;
        this.offset = 50;

        this.progressPath.style.transition = this.progressPath.style.WebkitTransition = 'none';
        this.progressPath.style.strokeDasharray = this.pathLength + ' ' + this.pathLength;
        this.progressPath.style.strokeDashoffset = this.pathLength;
        this.progressPath.getBoundingClientRect();
        this.progressPath.style.transition = this.progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

        this.$window.addEventListener("scroll",  () => {
            this.scrollSpy();
        });
    }

    scrollToTop(){
        $('html, body').animate({scrollTop: 0}, this.duration);
    }

    scrollSpy(){
        if (this.$window.scrollY > this.offset) {
            $('.progress-wrap').addClass('active-progress');
        } else {
            $('.progress-wrap').removeClass('active-progress');
        }

        const scroll = this.$window.scrollY;
        const height = this.$document.height() - $(window).height();
        this.progressPath.style.strokeDashoffset =  this.pathLength - (scroll * this.pathLength / height);
    }
}

module.exports = {
    template: require('./wsBackToTop.html'),
    controllerAs: 'ctrl',
    controller: wsBackToTop,
};

import * as ACTIONS from '../../../reduxLoop/actions/contentActions';
import { HEADER_MOBILE_MENU_KEY } from '../../../reduxLoop/static/constants';
import contentUtils from '../../../utils/contentUtils';

class wsMobileMenu {
  constructor($scope, $window, $ngRedux, $sce, settings) {
    'ngInject';

    this.$scope = $scope;
    this.$window = $window;
    this.$scope = $scope;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.source = `/content/${HEADER_MOBILE_MENU_KEY}`;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      body: this.$sce.trustAsHtml(contentUtils.decodeHTML(state.vm.header.mobileMenu.html)),
      loading: state.vm.header.mobileMenu.loading,
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.source));
  }
}

export default {
  template: '<div ng-bind-html="ctrl.body"></div>',
  controllerAs: 'ctrl',
  controller: wsMobileMenu,
};

class wsContentBlockReferences {
  constructor(settings, $scope) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
  }

  $onInit() {
    if (this.sItem.type === 'EXAMPLE' && this.sItem.importance !== 'LOW') {
      this.open = true;
    }
  }
}

module.exports = {
  template: require('./wsContentBlockReferences.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockReferences,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

import { websitesApi } from '../api/apiConfig';
import settings from '../../config/settings';

// eslint-disable-next-line import/prefer-default-export
export const loadWebconfigurations = () =>
  websitesApi.getAll("/web/pages", {
    expand: "results.website,results.template",
    website: settings.website,
    limit: 5000,
  });

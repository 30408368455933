/* eslint-disable max-len */
import { cloneDeep } from 'lodash';

// TODO if this is a shared module we need to include contentUtils and utils here aswell
import contentUtils from '../../utils/contentUtils';
import utils from '../../utils/utils';
import { getWebpageInfoFromHref } from '../../reduxLoop/helpers/themaHelpers';
import { HOMEPAGE_COVER_IMAGE, DEFAULT_COVER_IMAGE } from '../../reduxLoop/static/constants';
import constants from '../../components/constants';

const uuid = require('uuid');

function getContactDetails(foundItem) {
  const contacts = foundItem.contacts || [];
  const hardcoded = []; // TODO require('../config/contacts');
  const foundContacts = hardcoded.filter(o => o.content === foundItem.$$meta.permalink);
  foundContacts.forEach(o => contacts.push(o.details));
  return contacts;
}

function calcTypeFromParent(parent) {
  if (parent.pageType === 'MINI_DATABASE') {
    return 'MINI_DATABASE_ITEM';
  }
  if (parent.pageType === 'BLOG') {
    return 'BLOG_ITEM';
  }
  if (parent.pageType === 'THEME_HOME') {
    return 'THEME_DETAIL';
  }

  return null;
}

function calcType(href, level, parent, webconfigurations) {
  const webpage = getWebpageInfoFromHref(href, webconfigurations);
  if (level === 0) {
    if (webpage) {
      switch (webpage.type) {
        case 'MINI_DATABASE':
          return 'MINI_DATABASE';
        case 'BLOG':
          return 'BLOG';
        case 'THEME_HOME_PICTURE':
        case 'THEME_HOME_TEXT':
        case 'THEME_HOME_FULL':
          return 'THEME_HOME';
        default: // case 'THEME_PAGE':
          return 'THEME_DETAIL_ROOT';
      }
    } else {
      // console.warn('no type: ' + href);
      return null;
    }
  }
  if (level === 1) {
    if (webpage) {
      switch (webpage.type) {
        case 'MINI_DATABASE':
          return 'MINI_DATABASE';
        case 'BLOG':
          return 'BLOG';
        default: // case 'THEME_PAGE':
          return 'THEME_DETAIL';
      }
    } else {
      return calcTypeFromParent(parent);
    }
  }
  if (level > 1) {
    return calcTypeFromParent(parent);
  }

  return null;
}

function calcPath(href, webconfigurations) {
  const webpage = getWebpageInfoFromHref(href, webconfigurations);
  return webpage ? webpage.path : href;
}

function calcFacets(href, webconfigurations) {
  const webpage = getWebpageInfoFromHref(href, webconfigurations);
  return webpage ? webpage.facets : null;
}

function findItem(items, href) {
  return items.filter(o => o.$$meta.permalink === href)[0];
}

function getRegions(foundItem) {
  if (!foundItem.coverage || foundItem.coverage.length === 5) {
    return null;
  }

  return constants.REGIONS.filter(
    region => foundItem.coverage.includes(region.href),
  ).map(region => region.tag);
}

function getEducationLevels(foundItem) {
  if (!foundItem.mainstructures || !foundItem.outypes) {
    return null;
  }

  let educationLevelTags = [];

  if (foundItem.outypes.includes('CVO')) {
    educationLevelTags.push('vwo');
  }

  if (foundItem.outypes.includes('BOARDING')) {
    educationLevelTags.push('int');
  }

  if (foundItem.outypes.includes('SCHOOL')) {
    foundItem.mainstructures.forEach((mainstructure) => {
      const educationLevel = constants.EDUCATION_LEVELS.find(
        el => el.mainstructures && el.mainstructures.includes(mainstructure),
      );

      if (educationLevel) {
        educationLevelTags.push(educationLevel.tag);
      }
    });
  }

  educationLevelTags = [...new Set(educationLevelTags)];

  if (!foundItem.outypes || educationLevelTags.length === 5) {
    return null;
  }

  return educationLevelTags;
}

function addItems(item, $$relationsTo, level, allItems, webconfigurations) {
  const isPartOfRelations = $$relationsTo.filter(o => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype));
  if (isPartOfRelations.length > 0) {
    item.children = [];
    isPartOfRelations.forEach((relation) => {
      const foundItem = findItem(allItems, relation.$$expanded.from.href);
      if (foundItem) {
        const themeItem = {
          level,
          parent: item,
          href: foundItem.$$meta.permalink,
          pageType: calcType(foundItem.$$meta.permalink, level, item, webconfigurations),
          pagePath: calcPath(foundItem.$$meta.permalink, webconfigurations),
          facets: calcFacets(foundItem.$$meta.permalink, webconfigurations),
          key: foundItem.key ? foundItem.key : null,
          readOrder: relation.$$expanded.readorder,
          type: foundItem.type ? foundItem.type : null,
          title: foundItem.title ? foundItem.title : null,
          shortDescription: foundItem.shortdescription ? foundItem.shortdescription : null,
          description: foundItem.description ? foundItem.description : null,
          startAge: foundItem.startage ? foundItem.startage : null,
          endAge: foundItem.endage ? foundItem.endage : null,
          $$html: foundItem.$$html ? foundItem.$$html : null,
          identifier: foundItem.identifiers && foundItem.identifiers.length > 0
            ? foundItem.identifiers[0] : null,
          coverImage: contentUtils.getImageWithSize(foundItem, 'COVER_IMAGE', { w: 2000, h: 1600 }, item.coverImage),
          theme: foundItem.themes && foundItem.themes.length > 0
            ? foundItem.themes : null,
          thumbImage: contentUtils.getImage(foundItem, 'THUMBNAIL'),
          thumbImageM: contentUtils.getImageWithSize(foundItem, 'THUMBNAIL', { w: 500, h: 360 }),
          thumbImageS: contentUtils.getImageWithSize(foundItem, 'THUMBNAIL', { w: 100, h: 100 }),
          image: contentUtils.getImage(foundItem, 'ILLUSTRATION'),
          imageS: contentUtils.getImageWithSize(foundItem, 'ILLUSTRATION', { w: 300 }),
          imageM: contentUtils.getImageWithSize(foundItem, 'ILLUSTRATION', { w: 800, h: 1000 }),
          videoPlayback: contentUtils.calcVideoPlayback(foundItem),
          attachment: contentUtils.getAttachment(foundItem, ['ATTACHMENT', 'CONTENT']),
          references: contentUtils.addReferences(foundItem),
          requirements: contentUtils.addRequirements(foundItem),
          contacts: getContactDetails(foundItem),
          links: contentUtils.addReferences(foundItem),
          importance: foundItem.importance ? foundItem.importance : 'MEDIUM',
          externalReference: contentUtils.calcExternalLink(foundItem),
          created: foundItem.$$meta.created,
          creators: foundItem.creators,
          issued: foundItem.issued,
          regionTags: getRegions(foundItem),
          educationLevelTags: getEducationLevels(foundItem),

          visible: true,
          relationsFrom: foundItem.$$relationsFrom,
        };

        addItems(themeItem, foundItem.$$relationsTo, level + 1, allItems, webconfigurations);
        item.children.push(themeItem);
      } else {
        // console.warn('Item not found: ' + relation.$$expanded.from.href);
      }
    });
    item.children.sort((a, b) => a.readOrder - b.readOrder);
    if (item.facets && item.facets.find(facet => facet.component === 'FULL_TEXT_SEARCH')) {
      utils.createIndexAsync(item).then((searchIndex) => {
        item.searchIndex = searchIndex;
      }).catch((err) => {
        console.error('create index failed', err);
      });
    }
  }
}


function buildTreeFromItemList(rootKey, allItems, webconfigurations) {
  try {
    const root = allItems.filter(o => o.key === rootKey)[0];
    // const type = calcType(root.$$meta.permalink, 0, null);
    const pagePath = calcPath(root.$$meta.permalink, webconfigurations);

    const themeTree = {
      href: root.$$meta.permalink,
      // pageType: type !== 'THEME_HOME' && hasTrainings ? 'THEME_HOME_TEXT' : type,
      pagePath,
      facets: calcFacets(root.$$meta.permalink, webconfigurations),
      key: root.key ? root.key : null,
      title: root.title ? root.title : null,
      tags: root.tags ? root.tags : null,
      issued: root.issued,
      isIssued: (root.issued && (new Date(root.issued) < new Date())),
      description: root.description ? root.description : null,
      coverImage: contentUtils.getImage(root, 'COVER_IMAGE', DEFAULT_COVER_IMAGE),
      sharedCoverImage: contentUtils.getImageWithSize(root, 'COVER_IMAGE', { w: 2000, h: 1600 }, HOMEPAGE_COVER_IMAGE),
      sharedThumbImage: contentUtils.getImageWithSize(root, 'THUMBNAIL', { w: 500, h: 360 }, HOMEPAGE_COVER_IMAGE),
      contacts: getContactDetails(root),
      root: {
        // TODO: put real information
        // color: '#B72A8D',
        // color: settings.defaultColor,
        icon: 'https://cached-api.katholiekonderwijs.vlaanderen/content/35f254cc-4437-4862-bdd7-f555172cfdec/veldicoontjes-02.svg?bf05a5b1-753d-3d6a-3df5-6f5cf1dc2aa4',
        title: root.title ? root.title : null,
      },
      references: contentUtils.addReferences(root),
      requirements: contentUtils.addRequirements(root),
      created: root.$$meta.created,
      children: [],
      coverage: root.coverage,
      mainstructures: root.mainstructures,
      outypes: root.outypes,
      fullCoverage: root.coverage,
      fullMainstructures: root.mainstructures,
      fullOutypes: root.outypes,
      visible: true,
      level: 0,
    };
    addItems(themeTree, root.$$relationsTo, 1, allItems, webconfigurations);
    // themeTree.children = dataService.getApplicabilityLabels(themeTree.children);

    return themeTree;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

function addTrainings(theme) {
  let description = 'Overzicht van nascholingen, vormingen, netwerken …';
  if (theme.pageTypeModified && theme.children[0].shortDescription === null) description = '';

  return {
    key: uuid.v4(),
    referenceFrameItem: theme.referenceFrameItem,
    pagePath: `${theme.pagePath}/professionalisering`,
    type: 'SECTION',
    href: 'training',
    pageType: 'THEME_TRAINING',
    coverImage: 'https://cached-api.katholiekonderwijs.vlaanderen/content/587f3776-bd35-4f56-a7be-9e69fd8d044c/attachments/shutterstock_291602753%20(1).jpg?hash=a64a57f9-480d-dafb-7ced-a5f5fae36e1a-sprint-220-0-r',
    thumbImage: true,
    visible: true,
    title: 'Professionalisering',
    description: true,
    shortDescription: description,
    thumbImageM: {
      href: 'https://cached-api.katholiekonderwijs.vlaanderen/content/587f3776-bd35-4f56-a7be-9e69fd8d044c/attachments/shutterstock_291602753%20(1).jpg?hash=a64a57f9-480d-dafb-7ced-a5f5fae36e1a-sprint-220-0-r',
    },
  };
}

function addNews(theme) {
  return {
    key: uuid.v4(),
    referenceFrameItem: theme.referenceFrameItem,
    pagePath: `${theme.pagePath}/naschalingsdatabank`,
    type: 'SECTION',
    href: 'news',
    pageType: 'THEME_NEWS',
    coverImage: 'https://cached-api.katholiekonderwijs.vlaanderen/content/587f3776-bd35-4f56-a7be-9e69fd8d044c/attachments/shutterstock_291602753%20(1).jpg?hash=a64a57f9-480d-dafb-7ced-a5f5fae36e1a-sprint-220-0-r',
    thumbImage: true,
    visible: true,
    title: 'naschalingsdatabank',
    description: true,
    shortDescription: 'naschalingsdatabank',
    thumbImageM: {
      href: 'https://cached-api.katholiekonderwijs.vlaanderen/content/587f3776-bd35-4f56-a7be-9e69fd8d044c/attachments/shutterstock_291602753%20(1).jpg?hash=a64a57f9-480d-dafb-7ced-a5f5fae36e1a-sprint-220-0-r',
    },
  };
}


function buildNewRootChild(theme, path) {
  const key = uuid.v4();
  let thumbImage = false;
  let thumbImageM = null;
  const { description } = theme;

  if (theme.coverImage) {
    thumbImage = true;
    thumbImageM = theme.coverImage;
  }

  return {
    ...cloneDeep(theme),
    key,
    type: 'SECTION',
    thumbImage,
    thumbImageM,
    shortDescription: description,
    pagePath: path,
    href: `/content/${key}`,
    $$meta: {
      permalink: `/content/${key}`,
    },
  };
}

export const flattenTree = (tree, onlyVisible = true) => {
  const flatList = [];
  function addFlatItems(items) {
    items.forEach((item) => {
      flatList.push(item);
      if (item.children) {
        addFlatItems(item.children.filter(child => !onlyVisible || child.visible));
      }
    });
  }

  if (tree && (!onlyVisible || tree.visible)) {
    flatList.push(tree);
    if (tree.children) {
      addFlatItems(tree.children.filter(child => !onlyVisible || child.visible));
    }
  }
  return flatList;
};

export const flattenTreeFromContentList = (root, childrenMap) => {
  const flatMap = [];
  function addItems(items) {
    items.forEach((item) => {
      flatMap.push(item);
      const isPartOfRelations = item.$$relationsTo.filter(o => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype));
      if (isPartOfRelations.length > 0) {
        addItems(isPartOfRelations.map(
          relation => childrenMap.get(relation.$$expanded.from.href),
        ));
      }
    });
  }

  if (root) {
    flatMap.push(root);
    const isPartOfRelations = root.$$relationsTo.filter(o => ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.$$expanded.relationtype));
    if (isPartOfRelations.length > 0) {
      addItems(isPartOfRelations.map(relation => childrenMap.get(relation.$$expanded.from.href)));
    }
  }
  return flatMap;
};

// eslint-disable-next-line import/prefer-default-export
export const buildTree = (key, items, webconfigurations) => {
  // console.log('BUILD TREE');

  const tree = buildTreeFromItemList(key, items, webconfigurations);

  // it's not needed for www but if we want a shared module with pro we need it
  // if (tree.hasTeasers) {
  //   const referenceFrame = await getReferenceFrame();
  //   const themeReferenceFrame = referenceFrame.find(theme => theme.title.trim().toLowerCase() === tree.title.trim().toLowerCase());
  //   tree.themeReferenceItem = themeReferenceFrame;
  //   tree.preLoadTeasers = true;
  // }

  if (tree.hasTrainings) {
    tree.children.push(addTrainings(tree));
  }
  // console.log('CONTENT TREE:', tree);
  return tree;
};

export const buildFlattenTree = (key, items) => {
  console.log('BUILD FLATTEN TREE');

  const tree = buildTree(key, items);
  return flattenTree(tree, false);
};

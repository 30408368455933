require('./wsContentBlockLegal.scss');

class wsContentBlockLegal {
  constructor() {
    'ngInject';
  }
}

module.exports = {
  template: require('./wsContentBlockLegal.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockLegal,
  bindings: {
    sItem: '<',
  },
};

import settings from '../config/settings';
import { getCurrentPageWebConfigurationType } from '../reduxLoop/helpers/themaHelpers';
import { deleteCookie } from '../reduxLoop/helpers/cookiesHelpers';

let { referrer } = window.document;

const stringifiedPiwikScript = `(function(window, document, dataLayerName, id) {
    window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');\
    function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
    var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
    var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
    tags.async=!0,tags.src="https://kathondvla.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
    !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
    })(window, document, 'dataLayer', '${settings.piwikAnalytics.token}');`
  .replace(/\n/g, '')
  .replace(/\s+/g, ' '); // to make it look better on the script tag..

const service = ($state, $location, $ngRedux) => {

  const registerScriptTags = () => {
    if (document.getElementById('piwikAnalyticsTag')) return;
    const bodyFirstElement = document.body.children[0];
    const piwikAnalyticsScript = document.createElement('script');
    piwikAnalyticsScript.id = 'piwikAnalyticsTag';
    piwikAnalyticsScript.innerText = stringifiedPiwikScript;
    piwikAnalyticsScript.type = 'text/javascript';
    document.body.insertBefore(piwikAnalyticsScript, bodyFirstElement);
  };

  const deleteGoogleCookies = () =>{
    const analyticsCookies = document.cookie.match(/(_ga|_gid)(.*?)(?==)(?=.+?(;|$))/g);
    if (analyticsCookies?.length) {
      analyticsCookies.forEach(cookieName => deleteCookie(cookieName, '.katholiekonderwijs.vlaanderen'));
    }
  };

  const deleteAnalytics = () => {
    window.dataLayer = [];
    const piwikTag = document.getElementById('piwikAnalyticsTag');
    if (piwikTag) {
      /* if piwik script was already loaded and cookies are later rejected
      is not enough to remove the script, we need to reload to ensure nothing is sent */
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  };

  const trackEvent = (eventName, extraParams = {}, statusCode = 200) => {
    const stateName = getCurrentPageWebConfigurationType($ngRedux.getState()) || $state.$current.name;
    const payload = {
      event: eventName,
      user_id: undefined,
      user_authstatus: 'anonymous',
      user_login_method: undefined,
      user_region: undefined,
      user_edu_level: undefined,
      user_target_group: undefined,
      page_hostname: $location.$$host,
      page_pathname: $location.$$path,
      page_referrer: referrer,
      page_template: stateName?.toLowerCase() || undefined,
      page_category_1: undefined,
      page_category_2: undefined,
      page_category_3: undefined,
      page_category_4: undefined,
      page_category_5: undefined,
      page_category_6: undefined,
      page_access: 'granted',
      page_status_code: statusCode,
      ...extraParams,
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(payload);
    const piwikTag = document.getElementById('piwikAnalyticsTag');
    if (piwikTag) {
      console.log('piwik-event-dispatch', (['test', 'acc'].includes(settings.environment) && payload) || '');
    }
  };

  const setReferrer = (url) => {
    referrer = url;
  };

  return {
    registerScriptTags,
    deleteGoogleCookies,
    deleteAnalytics,
    trackEvent,
    setReferrer,
  };
};


angular.module('services').factory('Analytics', service);

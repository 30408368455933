class wsContentBlockExample {
  constructor(settings, $scope, utils, routerService) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.utils = utils;
    this.routerService = routerService;
  }
}

module.exports = {
  template: require('./wsContentBlockExample.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockExample,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};


class wsHeaderLoading {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
  }
}

export default {
  template: require('./wsHeaderLoading.html'),
  controllerAs: 'ctrl',
  controller: wsHeaderLoading,
  bindings: {
  },
};

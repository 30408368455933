
const FILE_TYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'word',
  'application/msword': 'word',
  'application/pdf': 'pdf',
  'application/xml': 'xml',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'excel',
  'application/vnd.ms-excel': 'excel',
  'video/mp4': 'video',
  'audio/mp3': 'audio',
  'audio/mpeg': 'audio',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.template': 'powerpoint',
  'image/jpg': 'image',
  'image/png': 'image',
  'image/jpeg': 'image',
  'image/gif': 'image',
};

const REGIONS = [{
  href: '/dioceses/1',
  tag: 'ant',
  title: 'Antwerpen',
  checked: false,
}, {
  href: '/dioceses/2',
  tag: 'm-b',
  title: 'Mechelen-Brussel',
  checked: false,
}, {
  href: '/dioceses/3',
  tag: 'lim',
  title: 'Limburg',
  checked: false,
}, {
  href: '/dioceses/4',
  tag: 'o-vl',
  title: 'Oost-Vlaanderen',
  checked: false,
}, {
  href: '/dioceses/5',
  tag: 'w-vl',
  title: 'West-Vlaanderen',
  checked: false,
}];

const EDUCATION_LEVELS = [
  {
    mainstructures: [
      '/mainstructures/a3eb0c96-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb0f5c-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb10b0-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb151a-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: '(bu)bao',
    title: '(Buitengewoon) basisonderwijs',
    checked: false,
  }, {
    mainstructures: [
      '/mainstructures/a3eb1646-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb1c72-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: '(bu)so',
    title: '(Buitengewoon) secundair onderwijs',
    checked: false,
  }, {
    mainstructures: [
      '/mainstructures/a3eb2212-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'dko',
    title: 'Deeltijds kunstonderwijs',
    checked: false,
    trainingReadOrder: 10,
  }, {
    mainstructures: [
      '/mainstructures/a3eb1b50-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb1eac-a3a4-11e3-ace8-005056872b95',
    ],
    noMainstructureNeeded: true,
    ouType: 'CVO',
    tag: 'vwo',
    title: 'Volwassenenonderwijs',
    checked: false,
    trainingReadOrder: 8,
  }, {
    mainstructures: [
      '/mainstructures/a3eb1d94-a3a4-11e3-ace8-005056872b95',
      '/mainstructures/a3eb20fa-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'ho',
    title: 'Hoger onderwijs',
    checked: false,
    trainingReadOrder: 9,
  }, {
    ouType: 'BOARDING',
    tag: 'int',
    title: 'Internaten',
    checked: false,
    trainingReadOrder: 7,
  }];


const EXTRA_EDUCATION_LEVELS = [// //// extra
  {
    mainstructures: [
      '/mainstructures/a3eb0c96-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'gk',
    title: 'Gewoon kleuteronderwijs',
    checked: false,
    trainingReadOrder: 2,
  }, {
    mainstructures: [
      '/mainstructures/a3eb0f5c-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'bk',
    title: 'Buitengewoon kleuteronderwijs',
    checked: false,
    trainingReadOrder: 1,
  }, {
    mainstructures: [
      '/mainstructures/a3eb10b0-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'glo',
    title: 'Gewoon lager onderwijs',
    checked: false,
    trainingReadOrder: 4,
  }, {
    mainstructures: [
      '/mainstructures/a3eb151a-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'blo',
    title: 'Buitengewoon lager onderwijs',
    checked: false,
    trainingReadOrder: 3,
  }, {
    mainstructures: [
      '/mainstructures/a3eb1646-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'gso',
    title: 'Gewoon secundair onderwijs',
    checked: false,
    trainingReadOrder: 6,
  }, {
    mainstructures: [
      '/mainstructures/a3eb1c72-a3a4-11e3-ace8-005056872b95',
    ],
    ouType: 'SCHOOL',
    tag: 'bso',
    title: 'Buitengewoon secundair onderwijs',
    checked: false,
    trainingReadOrder: 5,
  }];

const COOKIE_CONTENT = {
  noodzakelijke:'fbfcec70-01d6-4704-a6f6-86722b6b0368',
  statistische:'537becbc-2561-4812-8a97-746e7502fdc8',
  socialemedia:'6e3633b0-86a6-4827-893b-eb823ef88612'
};

export default {
  FILE_TYPES,
  REGIONS,
  EDUCATION_LEVELS,
  EXTRA_EDUCATION_LEVELS,
  COOKIE_CONTENT
};

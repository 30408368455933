class wsContentBlock {
  constructor(settings, $scope, utils) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.utils = utils;
  }

  $onInit() {
    this.sLevel = parseInt(this.sLevel, 10);
    this.$scope.$emit('wsContentBlock:loaded', this.sItem.href);
  }
}

module.exports = {
  template: require('./wsContentBlock.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlock,
  bindings: {
    sItem: '<',
    sLevel: '<',
    sColor: '<',
    sClass: '<',
    sHideAttachments: '<',
  },
};

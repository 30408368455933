import { isObject } from 'lodash';
import * as CONTENT_ACTIONS from '../reduxLoop/actions/contentActions';

/* eslint-disable no-use-before-define */
const CircularJSON = require('circular-json');

const service = (settings, $timeout, $ngRedux) => {
  'ngInject';
  'use strict';

  const data = {
    originWindow: null,
    addListener,
    removeListener,
    checkMessage,
    postMessage,
    setOriginWindow,
    notifyRedactiePageLoaded,
    listening: false,
    noRedactie: false,
  };

  function addListener(callback, scope) {
    if (setOriginWindow()) {
      addPostMessageListener(callback, scope);
      data.noRedactie = false;
    } else {
      data.noRedactie = true;
    }
  }

  function removeListener() {
    window.removeEventListener('message', data.callback, false);
    data.noRedactie = false;
  }

  function setOriginWindow() {
    let originWindow = window.opener;

    if (!originWindow) {
      if (window !== window.parent) {
        originWindow = window.parent;
      }
    }
    data.originWindow = originWindow;
    return originWindow;
  }

  function getTrustedOrigins() {
    return settings.trustedOrigins.join(',');
  }

  function addPostMessageListener(callback, scope) {
    data.callback = scope ? callback.bind(scope.ctrl) : callback;

    if (!data.listening) {
      window.addEventListener('message', data.callback, false);

      if (data.originWindow) {
        postMessage({ state: 'CORRECT', event: 'LOADED', source: 'PRO' });
      }
      data.listening = true;
    }
  }

  async function checkMessage(event) {
    console.log('[POST_MESSAGE]', event);
    if (!event.data) {
      throw new Error('There is no value in data property');
    }

    if (!(isObject(event.data) && Object.keys(event.data).length)) {
      throw new Error('Data must be an object and it must not be empty');
    }

    if (event.data.type === 'REDACTIE_SUGGESTIONS') {
      console.log('[POST_MESSAGE]', event);
      let { suggestions } = event.data; // new way, redactie doesn't circularJSON the data anymore
      if (typeof suggestions === 'string') {
        suggestions = CircularJSON.parse(suggestions);
      }
      $ngRedux.dispatch(CONTENT_ACTIONS.setContentTree({
        href: suggestions.webpages[0].source.href,
        items: suggestions.items,
      }));
    }
  }

  function postMessage(body) {
    data.originWindow.postMessage(body, getTrustedOrigins());
  }

  function notifyRedactiePageLoaded() {
    $timeout(() => {
      const redactieParam = window.location.search.includes('redactie');
      redactieParam &&
        data.addListener((event) => {
          if (event.data.type === 'REDACTIE_SUGGESTIONS') {
            data.checkMessage(event);
          }
        });
    }, 500);
  }

  return data;
};

angular.module('services').factory('postMessageService', service);

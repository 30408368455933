/* eslint-disable max-len */
import { WWW_CURRENT_THEME_PAGE } from '../static/constants';
import { getCurrentPageWebConfigurationType } from '../helpers/themaHelpers';
import { getMetatags } from '../helpers/helpers';
import utils from '../../utils/utils';

// first section in the first level of the content tree
function recapSection(tree) {
  if (!tree) {
    return {};
  }
  const section = tree.children.filter(child => child.type === 'SECTION')[0];
  if (!section) {
    return {};
  }

  return {
    key: section.key,
    title: section.title,
    items: section.children,
  };
}

// second section in the first level of the content tree
function visionSection(tree) {
  if (!tree) {
    return {};
  }
  const section = tree.children.filter(child => child.type === 'SECTION')[1];
  if (!section) {
    return {};
  }

  return {
    key: section.key,
    title: section.title,
    items: section.children,
    coverImage: section.coverImage.href,
  };
}

function explanationSection(tree) {
  if (!tree) {
    return {};
  }
  const section = tree.children.filter(child => child.type === 'SECTION')[2];
  if (!section) {
    return {};
  }

  return {
    key: section.key,
    title: section.title,
    items: section.children.filter(child => child.type === 'SECTION'),
  };
}

function tocSections(tree) {
  if (!tree) {
    return [];
  }
  return tree.children.filter(child => child.type === 'SECTION').map(child => ({ title: child.title, key: child.key }));
}

function proUrl(tree) {
  const linkGroup = tree.children.find(c => c.type === 'LINK_GROUP');
  if (linkGroup && linkGroup.children.length) {
    // we always get the first link added in the group
    const reference = linkGroup.children[0];
    // TODO we should be able to use reference.links but is not filling it correctly
    return reference.relationsFrom
      .find(rel => rel.$$expanded.relationtype === 'REFERENCES')
      .$$expanded.to.href;
  }
  return undefined;
}

function isThemeDetail(state) {
  return getCurrentPageWebConfigurationType(state) === WWW_CURRENT_THEME_PAGE;
}

export default (tree, state) => {
  if (!tree || !isThemeDetail(state)) {
    return {
      loading: true,
    };
    // return undefined;
  }

  return {
    title: tree.title,
    coverImage: tree.coverImage.href,
    recapSection: recapSection(tree),
    visionSection: visionSection(tree),
    explanationSection: explanationSection(tree),
    tocSections: tocSections(tree),
    proUrl: proUrl(tree),
    loading: !tree,
    metatags: getMetatags(state, tree),
    date: utils.getFormattedDate(tree.issued),
  };
};

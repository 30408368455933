class wsContentBlockSummary {
  constructor(settings, $scope, $element) {
    'ngInject';

    this.$element = $element;
  }

  $onInit() {
    this.$element.append(`<style>ws-content-block-summary .theme-content-synthese h2:before{background: ${this.sColor} !important;}</style>`);
  }
}

module.exports = {
  template: require('./wsContentBlockSummary.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockSummary,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

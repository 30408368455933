import settings from '../../config/settings';

export default (analytics, online) => {
  analytics.deleteGoogleCookies();
  if (settings.piwikAnalytics.enabled && online) {
    analytics.registerScriptTags();
  }
};

export const checkRejectedTracking = (analytics, online) => {
  if (online) {
    analytics.registerScriptTags();
  } else {
    analytics.deleteAnalytics();
  }
};

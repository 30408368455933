class wsLoadMore {
    constructor($scope) {
      'ngInject';
  
      this.$scope = $scope;
    }
  
    $onInit() {
      this.sMessage = this.sMessage || 'Laad meer';
    }
  
    loadMore() {
      this.$scope.$emit('wsLoadMore:loadMore');
    }
  }
  
  module.exports = {
    template: require('./wsLoadMore.html'),
    controllerAs: 'ctrl',
    controller: wsLoadMore,
    bindings: {
      sItemTotalCount: '<',
      sItemCount: '<',
      sMessage: '<',
    },
  };

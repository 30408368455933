require('./wsContentBlockCollapsed.scss');

class wsContentBlockCollapsed {
  constructor($stateParams, routerService) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.routerService = routerService;
  }

  $onInit() {
    if (this.$stateParams.scrollTo) {
      this.anchor = this.routerService.generateHash(this.sItem.key);
      this.open = this.anchor === this.$stateParams.scrollTo;
    }
  }
}

module.exports = {
  template: require('./wsContentBlockCollapsed.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockCollapsed,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

import * as ACTION_TYPES from '../constants/actionTypes';

export const loadHome = rootHref => ({
  type: ACTION_TYPES.LOAD_HOME,
  payload: rootHref,
});

export const setHomeReferences = result => ({
  type: ACTION_TYPES.SET_HOME_REFERENCES,
  payload: result,
});

export const setHomeNews = result => ({
  type: ACTION_TYPES.SET_HOME_NEWS,
  payload: result,
});

import { initSiteCookiesModal } from '../../reduxLoop/actions/cookiesActions';

const sanitizeHtml = require('sanitize-html');

class appRoot {
  constructor(settings, $scope, $location, $sessionStorage, $cookies, $uibModal, ngMeta,
    $window, $ngRedux, Analytics) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.$location = $location;
    this.$sessionStorage = $sessionStorage;
    this.$cookies = $cookies;
    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;
    this.analytics = Analytics;
    this.$window = $window;

    this.title = null;
    this.preview = false;
    this.ngMeta = ngMeta;
  }

  closeOverlayMenu() {
    $('#menu-mobile').removeClass('show');
    $('#overlay-menu').removeClass('show');
    $('.c-hamburger--htx').addClass('collapsed');
  }

  async $onInit() {
    this.$ngRedux.dispatch(initSiteCookiesModal(
      this.$uibModal,
      this.$cookies,
      this.$location,
      this.analytics,
    ));

    this.appLoaded = true;

    this.$scope.$on('showHeader', (event, show) => {
      this.showHeader = show;
      this.closeOverlayMenu();
    });

    this.$scope.$on('showCompactHeader', (event, show) => {
      this.showCompactHeader = show;
      this.closeOverlayMenu();
    });

    this.$scope.$on('leaveCookies', () => {
      // on leave /cookies screen => we need to open again the cookies constent modal if needed
      this.$ngRedux.dispatch(initSiteCookiesModal(
        this.$uibModal,
        this.$cookies,
        this.$location,
        this.analytics,
      ));
    });

    this.$scope.$on('$locationChangeStart', (event, current, previous) => {
      if (current !== previous) {
        this.analytics.setReferrer(previous);
      }
    });

    this.$scope.$on('dynamicMetaDataUpdated', (_event, metatags) => {
      const emptyTag = '&nbsp;';
      this.ngMeta.setTitle(metatags.title
        ? sanitizeHtml(metatags.title, { allowedTags: [], allowedAttributes: {} })
        : emptyTag);

      this.ngMeta.setTag(
        'description',
        metatags.description
          ? sanitizeHtml(metatags.description, { allowedTags: [], allowedAttributes: {} })
          : emptyTag,
      );
      this.ngMeta.setTag('image', metatags.image.href);
      this.ngMeta.setTag('imageWidth', metatags.image.sizes.width);
      this.ngMeta.setTag('imageHeight', metatags.image.sizes.height);
      this.ngMeta.setTag('robots', metatags.robots);
      this.ngMeta.setTag('googlebot', metatags.googlebot);
      window.prerenderReady = true;
    });
  }
}

export default {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  controller: appRoot,
};

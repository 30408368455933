const { NEWS_FILTER_TYPES } = require('../../../reduxLoop/static/constants');

require('./wsNewsFilter.scss');

class wsNewsFilter {
  constructor($scope, $location, moment) {
    'ngInject';

    this.$scope = $scope;
    this.$location = $location;
    this.moment = moment;
  }

  async $onInit() {
    this.newsTypes = NEWS_FILTER_TYPES.map(option => (
      {
        ...option,
        checked: this.filter.types && this.filter.types.split(',').includes(option.type),
      }
    ));
  }

  clearFilter() {
    this.$scope.$emit('wsNewsFilter:filterCleared');
  }

  sendFilter() {
    this.$scope.$emit('wsNewsFilter:filterNews', this.filter);
  }

  qTermKeypressed($event) {
    if ($event.type === 'keypress' && $event.keyCode === 13) {
      this.sendFilter();
    }
  }

  fromDateChanged() {
    console.log('Issued from changed', this.filter.van);
    this.sendFilter();
  }

  toDateChanged() {
    console.log('Issued to changed', this.filter.tot);
    this.sendFilter();
  }

  toggledType(option) {
    console.log('Toggled type to:', option.type, option.checked);

    let typesAsArray = this.filter.types ? this.filter.types.split(',') : [];
    if (option.checked) {
      typesAsArray.push(option.type);
    } else {
      typesAsArray = typesAsArray.filter(i => i !== option.type);
    }
    this.filter.types = typesAsArray.join(',');
    this.sendFilter();
  }

  openDropdown(target) {
    const targetArray = ['firstSelector', 'tabSelector', 'secondSelector', 'thirdSelector'];

    targetArray.forEach((targ) => {
      if (targ === target) {
        $(`#${targ}`).addClass('show');
      } else {
        $(`#${targ}`).removeClass('show');
      }
    });

    $('body').on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).parents('.kov-pro-news__filter-thema').length <= 0) {
        $(`#${target}`).removeClass('show');
        $('body').off('click');
      }
    });
  }
}

module.exports = {
  template: require('./wsNewsFilter.html'),
  controllerAs: 'ctrl',
  controller: wsNewsFilter,
  bindings: {
    filter: '<sFilter',
  },
};

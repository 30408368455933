import * as ACTION_TYPES from '../constants/actionTypes';

export const loadContentTree = results => ({
  type: ACTION_TYPES.LOAD_CONTENT_TREE,
  payload: results,
});

export const setContentTree = result => ({
  type: ACTION_TYPES.SET_CONTENT_TREE,
  payload: {
    href: result.href,
    items: result.items,
  },
});

const contentUtils = require('../../../../utils/contentUtils');

class wsContentBlockReferenceItem {
  constructor(settings, $scope, dataService, $element, utils, routerService) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.dataService = dataService;
    this.$element = $element;
    this.utils = utils;
    this.routerService = routerService;
  }

  $onInit() {
    this.$element.append(`<style>ws-content-block-reference-item a.list-item-info__title:hover {color: ${this.sColor} !important;}</style>`);
    this.$element.append(`<style>ws-content-block-reference-item a.kov-pro-blogitem__info-title:hover {color: ${this.sColor} !important;}</style>`);
    this.$element.append(`<style>ws-content-block-reference-item a.list-item-info__title h5:hover {color: ${this.sColor} !important;}</style>`);

    this.loadContent();
  }

  $onChanges(changes) {
    if (changes.sReferenceType && changes.sReferenceType.currentValue && this.contentDescendants
      && !this.contentDescription) {
      this.contentDescription = this.getItemExcerpt();
    }
  }

  async loadContent() {
    this.contentDescendants = await this.dataService.getDescendants(this.sItem.references);
    this.content = this.contentDescendants.find(
      item => item.$$meta.permalink === this.sItem.references[0],
    );
    this.content.href = this.content.$$meta.permalink;
    this.content.thumbImageS = contentUtils.getImageWithSize(this.content, 'THUMBNAIL', { w: 100, h: 100 });
    this.contentDescription = this.getItemExcerpt();
  }

  getItemExcerpt() {
    if (this.sReferenceType === 'MINI_DATABASE') {
      return this.content.shortdescription;
    }

    if (this.sReferenceType !== undefined) {
      const nodeMap = new Map(this.contentDescendants.map(item => [item.$$meta.permalink, item]));
      const flattenTree = this.utils.flattenTreeFromContentList(this.content, nodeMap);
      const firstParagraph = flattenTree.find(item => item.type === 'PARAGRAPH');
      return firstParagraph ? firstParagraph.$$html : '';
    }

    return undefined;
  }
}

module.exports = {
  template: require('./wsContentBlockReferenceItem.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockReferenceItem,
  bindings: {
    sItem: '<',
    sColor: '<',
    sReferenceType: '<',
  },
};

import * as ACTIONS from '../../../reduxLoop/actions/contentActions';
import { HEADER_MAIN_MENU_KEY } from '../../../reduxLoop/static/constants';
import contentUtils from '../../../utils/contentUtils';

class wsHeader {
  constructor($scope, $sce, $window, $ngRedux, settings) {
    'ngInject';

    this.$scope = $scope;
    this.$window = $window;
    this.$sce = $sce;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.fixedHeader = false;

    this.source = `/content/${HEADER_MAIN_MENU_KEY}`;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect(state => ({
      body: this.$sce.trustAsHtml(contentUtils.decodeHTML(state.vm.header.mainMenu.html)),
      loading: state.vm.header.mainMenu.loading,
      language: state.vm.header.language
    }))(this);

    this.$ngRedux.dispatch(ACTIONS.loadContentTree(this.source));
    this.scrollSpy();
    this.$window.addEventListener('scroll',  () => {
      this.scrollSpy();
    });
  }

  scrollSpy(){
    if (!this.fixedHeader && this.$window.scrollY > 110) {
      $('.combinedMenu').addClass('fixed-header');
      this.fixedHeader = true;
      $('#kov-compact-menu').show();
    } else if (this.fixedHeader && this.$window.scrollY < 40) {
      $('.combinedMenu').removeClass('fixed-header');
      $('#kov-compact-menu').hide();
      this.fixedHeader = false;
    }
  }

}

export default {
  template: require('./wsHeader.html'),
  controllerAs: 'ctrl',
  controller: wsHeader,
  bindings: {
    sTitle: '<',
    sTitlePath: '<',
  },
};

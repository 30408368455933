import { COOKIE_TYPE } from '../../reduxLoop/static/constants';
import { setCookies, closeCookiesModal } from '../../reduxLoop/actions/cookiesActions';

class wsCookieConsent {
  constructor($scope, $uibModal, $cookies, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.COOKIE_TYPE = COOKIE_TYPE;
    this.allUnselected = true;
    this.$ngRedux = $ngRedux;
    this.$cookies = $cookies;
  }

  $onInit() {
    this.cookies = this.resolve.selected;

    this.cookiesChanged();
  }

  cookiesChanged() {
    const anySelected = Object.keys(this.cookies).some(key => this.cookies[key]);
    this.allUnselected = !anySelected;
  }

  goToCookies() {
    this.$ngRedux.dispatch(closeCookiesModal());
  }

  acceptAll() {
    this.cookies = Object.keys(this.cookies).reduce((res, key) => {
      res[key] = true;
      return res;
    }, {});
    this.$ngRedux.dispatch(setCookies(this.cookies, this.$cookies));
  }

  acceptMinimal() {
    this.$ngRedux.dispatch(setCookies(this.cookies, this.$cookies));
  }

  acceptCustom() {
    this.$ngRedux.dispatch(setCookies(this.cookies, this.$cookies));
  }
}

export default {
  template: require('./wsCookieConsent.html'),
  controllerAs: 'ctrl',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: wsCookieConsent,
};

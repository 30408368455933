class wsContentBlockAttachments {
  constructor(Analytics) {
    'ngInject';

    this.analytics = Analytics;
  }

  $onInit() {
  }

  trackDownload(attachment) {
    const nameParts = attachment.attachment.name.split('.');
    const extension = nameParts.length === 1 ? '' : nameParts.pop();
    const name = nameParts.join('.');
    this.analytics.trackEvent('download_file', { file_name: decodeURIComponent(name?.toLowerCase()), file_extension: extension?.toLowerCase() });
  }
}

module.exports = {
  template: require('./wsContentBlockAttachments.html'),
  controllerAs: 'ctrl',
  controller: wsContentBlockAttachments,
  bindings: {
    sItem: '<',
    sColor: '<',
  },
};

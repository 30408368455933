/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */

import utils from '../../utils/utils';

// home news include those marked with importance=HIGH sorted by issued desc
// in case we don't have 7 important items then it will be filled with the rest sorted by issued desc
export const sortHomeNews = (news) => {
  const splittedNews = news
    .filter(n => n.issued)
    .reduce((res, n) => {
      if (n.importance === 'HIGH') {
        res.important.push(n);
      } else {
        res.rest.push(n);
      }
      return res;
    }, { important: [], rest: [] });
  return utils.sortByIssuedDate(splittedNews.important).concat(utils.sortByIssuedDate(splittedNews.rest));
};

/* eslint-disable max-len */
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from '../commands/contentCommands';
import * as ACTIONS from '../actions/contentActions';
import { TREE_ITEM_TYPES } from '../static/constants';

const initialState = {
  pages: {},
};

// eslint-disable-next-line import/prefer-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_CONTENT_TREE: {
      return loop({
        ...state,
      },
      Cmd.run(COMMANDS.loadRoot, {
        args: [action.payload, TREE_ITEM_TYPES],
        successActionCreator: ACTIONS.setContentTree,
        failActionCreator: error => console.log('ERROR loading content tree:', error),
      }));
    }

    case ACTION_TYPES.SET_CONTENT_TREE: {
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.href]: action.payload.items,
        },
      };
    }

    default:
      return state;
  }
};
